import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export const CloseIconStyled = styled(CloseIcon)`
  position: absolute;
  right: 100px;
  top: 50px;
  color: #fff;
  height: 2em;
  width: 2em;
  font-size: 2em;
  cursor: pointer;
  &:hover {
    color: #fafafa;
  }
`;

export const ModalOuterCont = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(3px) brightness(0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .title {
    color: white;
  }

  .continue {
    padding-inline: 2.5em;
    background-color: ${(props) => props.theme.secondary};
    color: white;
    padding-block: 0.75em;
    margin-top: 1em;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    &:active {
      transform: scale(0.99);
    }
  }
`;

export const ModalInnerCont = styled.div`
  width: 400px;
  max-width: 95%;
  background-color: transparent;
`;
