export const FAQs = [
  {
    question: "What is the process of ordering?",
    answer:
      "Select a frame-> Upload your photo-> Select an Address-> Select a payment method-> Order",
  },
  {
    question: "How do I pay for my order?",
    answer:
      "We accept all major credit cards and debit cards. We also accept PayPal.",
  },
  {
    question: "How do I cancel my order?",
    answer:
      "You can cancel your order within 24 hours of placing your order, contact us at support@momento.ink",
  },
  {
    question: "How do I change my order?",
    answer:
      "You can change your order within 24 hours of placing your order, contact us at support@momento.ink",
  },
  {
    question: "How do I change my address?",
    answer:
      "You can't change address after placing order, for any assistance contact us at support@momento.ink",
  },
  {
    question: "How do I change my payment method?",
    answer: "Once the order is placed, you can't change your payment method.",
  },
  {
    question: "How do I change my shipping method?",
    answer: "Once the order is placed, you can't change your shipping method.",
  },
  {
    question: "How do I track my order?",
    answer:
      "You will receive an email with tracking details, once we have processed your order.",
  },
  {
    question: "How do I return my order?",
    answer:
      "For any complaints and issues with order contact us at support@moment.ink",
  },
];
