export const mediaEndPoint = "https://api.momento.ink/media";
export const baseAddApi = "https://api.momento.ink/addApi";

export const sizeList = {
  L: "Large",
  M: "Medium",
  S: "Small",
  D: "Daimond",
};

export const sizeList2 = {
  L: 600,
  M: 300,
  S: 50,
  D: 100,
};
