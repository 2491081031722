import { device } from "assets/theme/device";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: max;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  align-self: center;
  .separate {
    display: flex;
    flex-direction: column;
  }
  .filterCont {
    align-self: end;
  }
  .sep {
    width: max;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1.4em;
    margin-bottom: 2em;
    z-index: 0;

    @media ${device.tablet} {
      width: 98%;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.4em;
    }

    @media ${device.mobileM} {
      width: fit;
      grid-template-columns: 1fr;
      padding-inline: 0em;
    }
  }
`;

export const FrameCont = styled.div`
  display: flex;
  font-family: Montserrat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  border-radius: 40px;
  // background-color: #fff;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  // border-radius: 10px 10px 10px 10px;
  width: 400px;
  max-width: 95%;
  @media ${device.mobileM} {
    max-width: 90%;
    margin: auto;
  }
  &:hover {
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  cursor: pointer;

  .frame {
    margin-top: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: ${(props) => (props.width ? props.width : 250)}px;
    height: ${(props) => props.height}px;
    @media ${device.mobileM} {
      margin-top: 2em;
    }
    position: relative;
    .frameImg {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .img {
      width: ${(props) => props.innerWidth}px;
      height: ${(props) => props.innerHeight}px;
      transform: scale(1);
      transform-origin: 0 0;
      margin: 0px;
      object-fit: cover;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2em;
    width: 100%;
    align-items: center;
    .desc {
      width: 100%;
      margin-top: 2em;
      font-size: 1.5em;
      font-family: Montserrat;
      text-align: center;
      color: #009999;
    }
    .size {
      font-weight: 600;
      margin-top: 0.1rem;
      opacity: 0.5;
    }
    .price {
      font-weight: 600;
      padding-bottom: 0.5em;
    }
    .rotateBtn {
      margin: 1em;
      align-items: center;
      display: flex;
    }
    .rotateBtn:hover {
      border-bottom: 1px solid #000;
    }
  }
`;
