import Footer from "Components/Footer/Footer";
import CropModal from "Components/Modal/CropModal";
import ForgotPassword from "Pages/Authentication/ForgotPassword/ForgotPassword";
import Login from "Pages/Authentication/Login/Login";
import Signup from "Pages/Authentication/Signup/Signup";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const Layout = (props) => {
  const [cropModal, setCropModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  return (
    <div>
      <Navbar
        isLoggedIn={props.isLoggedIn}
        setIsLoggedIn={props.setIsLoggedIn}
        setCropModal={setCropModal}
        setLoginModal={setLoginModal}
        setSignUpModal={setSignUpModal}
        isCartChanged={props.isCartChanged}
      />
      {cropModal && <CropModal setCropModal={setCropModal} />}
      {loginModal && (
        <Login
          setLoginModal={setLoginModal}
          setIsLoggedIn={props.setIsLoggedIn}
          setForgotPasswordModal={setForgotPasswordModal}
          setSignUpModal={setSignUpModal}
        />
      )}
      {signUpModal && (
        <Signup
          setLoginModal={setLoginModal}
          setIsLoggedIn={props.setIsLoggedIn}
          setSignUpModal={setSignUpModal}
        />
      )}
      {forgotPasswordModal && (
        <ForgotPassword
          setForgotPasswordModal={setForgotPasswordModal}
          setLoginModal={setLoginModal}
        />
      )}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
