import React from "react";
import AuthModal from "../AuthModal";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { login } from "apis/profile/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redirectReducer } from "../../../redux/features/frame";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirect = useSelector((state) => state.frame.redirect);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    if (loading || !email || !password || email === "" || password === "")
      return;
    setLoading(true);
    const body = {
      email: email,
      password: password,
    };
    e.preventDefault();

    const response = await login(body);
    if (response && response?.success) {
      console.log(response);
      localStorage.setItem("token", response.token);
      localStorage.setItem("refreshToken", response.refreshToken);
      props.setLoginModal(false);
      props.setIsLoggedIn(true);
    }
    if (redirect) {
      let tempRedirect = redirect;
      dispatch(redirectReducer(false));
      navigate(tempRedirect);
    }
    setLoading(false);
  };

  return (
    <AuthModal
      onClose={() => {
        if (redirect) {
          let tempRedirect = redirect;
          dispatch(redirectReducer(false));
          navigate(tempRedirect);
        }
        props.setLoginModal(false);
      }}
    >
      <HeaderRow>
        <h3>Login to Momento</h3>
      </HeaderRow>
      <Form>
        <TextField
          color="secondary"
          id="outlined-error"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className="passwd">
          {showPassword ? (
            <VisibilityIconStyled
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          ) : (
            <VisibilityIconStyled2
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          )}
          <TextField
            color="secondary"
            id="outlined-error-helper-text"
            label="Password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type={showPassword ? "text" : "password"}
          />
        </div>

        <Link
          to={"/forgot-password"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.setForgotPasswordModal(true);
            props.setLoginModal(false);
          }}
        >
          Forgot Password?
        </Link>

        <Button primary onClick={handleSubmit}>
          {loading ? <CircularProgress /> : "Login"}
        </Button>
        <Button
          onClick={(e) => {
            props.setLoginModal(false);
            props.setSignUpModal(true);
          }}
        >
          Signup
        </Button>
      </Form>
    </AuthModal>
  );
};

const Button = styled.button`
  background-color: ${(props) =>
    props.primary ? props.theme.main : props.theme.secondary};
  color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 0.5em;
  height: 3.2em;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    transform: scale(0.98);
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 2em;
  margin-bottom: 2em;
  margin-top: 3em;
  gap: 2em;
  a {
    color: ${(props) => props.theme.textSecondary};
    text-decoration: none;
    font-size: 0.875em;
    margin-top: -1.8em;
    margin-left: auto;
  }
  .passwd {
    width: 100%;
    height: 100%;

    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

const VisibilityIconStyled = styled(VisibilityOffIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 1.5em;
  color: ${(props) => props.theme.main};
  z-index: 1000;
  cursor: pointer;
  transform: translateY(-50%);
`;

const VisibilityIconStyled2 = styled(VisibilityIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 1.5em;
  color: ${(props) => props.theme.main};
  z-index: 1000;
  cursor: pointer;
  transform: translateY(-50%);
`;

export default Login;
