import React from "react";
import AuthModal from "../AuthModal";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import { signup } from "apis/profile/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redirectReducer } from "../../../redux/features/frame";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Signup = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.frame.redirect);

  const [email, setEmail] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [confPassword, setConfPassword] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [fullname, setFullname] = React.useState("");

  const handleSubmit = async (e) => {
    if (
      loading ||
      !email ||
      !phone ||
      !password ||
      email === "" ||
      password === "" ||
      password !== confPassword
    ) {
      if (email === null) setEmail("");
      if (phone === null) setPhone("");
      if (password === null) setPassword("");
      if (confPassword === null) setConfPassword("");
      return;
    }
    setLoading(true);
    e.preventDefault();
    const body = {
      email: email,
      phone: phone,
      password: password,
      fullname: fullname,
    };
    const response = await signup(body);
    if (response && response?.success) {
      // localStorage.setItem("token", response.token);
      // localStorage.setItem("refreshToken", response.refreshToken);
      props.setSignUpModal(false);
      props.setLoginModal(true);
    }

    setLoading(false);
  };

  return (
    <AuthModal
      onClose={() => {
        props.setSignUpModal(false);
      }}
    >
      <HeaderRow>
        <h3>Signup to Momento</h3>
      </HeaderRow>
      <Form>
        <TextField
          color="secondary"
          id="outlined-error"
          label="Full Name"
          name="fullname"
          value={fullname}
          onChange={(e) => {
            setFullname(e.target.value);
          }}
        />
        <TextField
          color="secondary"
          id="outlined-error"
          label="Email"
          name="email"
          error={
            email !== null &&
            /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
              email
            ) === false
          }
          value={email}
          helperText={
            email !== null &&
            /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
              email
            ) === false
              ? "Invalid Email"
              : ""
          }
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          color="secondary"
          id="outlined-error"
          label="Phone"
          name="phone"
          error={phone !== null && /^\d/.test(phone) === false}
          value={phone}
          helperText={
            phone !== null && /^\d/.test(phone) === false
              ? "Invalid Phone Number"
              : ""
          }
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <div className="passwd">
          {showPassword ? (
            <VisibilityIconStyled
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          ) : (
            <VisibilityIconStyled2
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          )}
          <TextField
            color="secondary"
            id="outlined-error-helper-text"
            label="Password"
            name="password"
            error={password !== null && password.length < 8}
            helperText={
              password === null
                ? ""
                : password.length < 8
                ? "Password must be at least 8 characters"
                : password !== confPassword
                ? ""
                : ""
            }
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type={showPassword ? "text" : "password"}
          />
        </div>
        <div className="passwd">
          {showPassword ? (
            <VisibilityIconStyled
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          ) : (
            <VisibilityIconStyled2
              onClick={(e) => setShowPassword((prev) => !prev)}
            />
          )}
          <TextField
            color="secondary"
            id="outlined-error-helper-text"
            label="Confirm Password"
            name="confPassword"
            error={
              confPassword !== null &&
              (password !== confPassword || confPassword.length < 8)
            }
            helperText={
              confPassword === null
                ? ""
                : confPassword.length < 8
                ? "Password must be at least 8 characters"
                : password !== confPassword
                ? "Confirm Password and Password must be same."
                : ""
            }
            value={confPassword}
            onChange={(e) => {
              setConfPassword(e.target.value);
            }}
            type={showPassword ? "text" : "password"}
          />
        </div>

        <Button primary onClick={handleSubmit}>
          {loading ? <CircularProgress color="primary" size={28} /> : "Signup"}
        </Button>
        <Button
          onClick={(e) => {
            props.setLoginModal(true);
            props.setSignUpModal(false);
          }}
        >
          Login
        </Button>
      </Form>
    </AuthModal>
  );
};

const Button = styled.button`
  background-color: ${(props) =>
    props.primary ? props.theme.main : props.theme.secondary};
  color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 0.5em;
  height: 3em;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    transform: scale(0.98);
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 2em;
  margin-bottom: 2em;
  margin-top: 3em;
  gap: 2em;
  a {
    color: ${(props) => props.theme.textSecondary};
    text-decoration: none;
    font-size: 0.875em;
    margin-top: -1.8em;
    margin-left: auto;
  }
  .passwd {
    width: 100%;
    height: 100%;

    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

const VisibilityIconStyled = styled(VisibilityOffIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 1.5em;
  color: ${(props) => props.theme.main};
  z-index: 1000;
  cursor: pointer;
  transform: translateY(-50%);
`;

const VisibilityIconStyled2 = styled(VisibilityIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 1.5em;
  color: ${(props) => props.theme.main};
  z-index: 1000;
  cursor: pointer;
  transform: translateY(-50%);
`;

export default Signup;
