import { mediaEndPoint, sizeList } from "apis/constants";
import { fetchAllOrders } from "apis/order/order";
import Loader from "Components/Loader/Loader";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  CartItemView,
  Container,
  InvoiceCont,
  OrderCont,
  StatusBar,
} from "./Order.styles";
import logo from "assets/images/logo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import Nothing from "Components/Nothing/Nothing";
import axios from "axios";
import ListAddresses from "Pages/Profile/ListAddresses";
import AuthModal from "Pages/Authentication/AuthModal";
import axiosApiInstance from "../../apis/axiosSetup";

import { toast } from "react-toast";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Order = () => {
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [creatingInvoice, setCreatingInvoice] = React.useState(false);
  const [invoice, setInvoice] = React.useState(null);
  const [addressModal, setAddressModal] = React.useState(false);
  const [modifyOrderId, setModifyOrderId] = React.useState("");

  const navigate = useNavigate();
  const invoiceRef = useRef(null);

  const fetchOrders = async () => {
    const response = await fetchAllOrders();
    if (response && response.success) setOrders(response.data);
    setLoading(false);
  };
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    onAfterPrint: () => {
      setInvoice(null);
      setCreatingInvoice(false);
    },
  });
  const handleViewClick = (item) => {
    setInvoice(item);
    setCreatingInvoice(true);
    setTimeout(() => {
      if (invoiceRef.current) {
        handlePrint();
      }
    }, 300);
  };

  const modifyOrderHandler = (order) => {
    setAddressModal(true);
    setModifyOrderId(order.oid);
  };

  const updateAddressHandler = async (addressId) => {
    await axiosApiInstance
      .put(`${process.env.REACT_APP_API_URL}/order/v1/modify`, {
        addressId,
        orderId: modifyOrderId,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Address Updated Successfully!");
          setAddressModal(false);
          fetchOrders();
        } else {
          toast.error("Failed to update address!");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const Invoice = () => {
    const { rotation, filter } = invoice;
    let index;

    if (rotation == "90" || rotation == "270") {
      index = {
        w: 1,
        h: 0,
      };
    } else {
      index = {
        w: 0,
        h: 1,
      };
    }

    let dime = invoice?.dim?.split("x");
    let ratio = dime[0] / dime[1];
    let width = 300;
    let height = ratio * width;
    let ibd = invoice?.ibd?.split("x");
    let obd = invoice?.obd?.split("x");
    if (ibd && obd) {
      ibd[0] = parseInt(ibd[0]);
      ibd[1] = parseInt(ibd[1]);
      obd[0] = parseInt(obd[0]);
      obd[1] = parseInt(obd[1]);
      ibd[0] = (ibd[0] / obd[0]) * width;

      ibd[1] = (ibd[1] / obd[1]) * height;
      obd[0] = width;
      obd[1] = height;
    }
    return (
      <InvoiceCont
        ref={invoiceRef}
        bgImg={mediaEndPoint + "/frames/" + invoice?.frame_key}
        height={height}
        width={width}
        innerHeight={ibd && ibd[index.h]}
        innerWidth={ibd && ibd[index.w]}
      >
        <div className="title">
          <img src={logo} alt="logo" />
          <h1>Invoice</h1>
        </div>
        <div className="invoice-body">
          <div className="invoice-body-top">
            <div className="invoice-body-top-left">
              <h2>Shipping Address</h2>
              <p>
                <span>{invoice?.fullname}</span>
                <br />
                <span>{invoice?.address}</span>
                <br />
                <span>{invoice?.city}</span>, <span>{invoice?.state}</span>
                <br />
                <span>Zip Code: {invoice?.zipcode}</span>
                <br />
                <span>Phone: {invoice?.phone}</span>
              </p>
            </div>
            <div className="invoice-body-top-right">
              <h2>Invoice #{invoice?.oid}</h2>
              <p>
                <span>Invoice Date: </span>
                <span>{new Date(invoice?.updated_at).toDateString()}</span>
                <br />
                <span>Transaction ID: </span>
                <span>{invoice?.transaction_id}</span>
                <br />
                <span>Order Date: </span>
                <span>{new Date(invoice?.updated_at).toDateString()}</span>
              </p>
            </div>
          </div>
          <div className="invoice-body-bottom">
            <div className="details">
              <h2>Order Details</h2>
              <div className="order-details">
                <div className="order-details-left">
                  <img
                    className="frameImg"
                    src={mediaEndPoint + "/frames/" + invoice?.frame_key}
                    alt="frame img"
                    style={{ transform: `rotate(${rotation}deg)` }}
                  />
                  <img
                    className={`img ${filter ? filter : ""}`}
                    src={mediaEndPoint + "/posters/" + invoice?.poster_key}
                    alt="poster"
                  />
                </div>
              </div>

              <div className="order-details-right">
                <h3>Product Details</h3>
                <ul>
                  <li>{invoice?.dsc}</li>
                  <li>Qty:1</li>
                  <li>High quality MDF wood</li>{" "}
                  <li>Scratch resistant 2mm glass</li>
                  <li>Ready to hang</li>
                  <li>Printed and hand assembled in the United States</li>
                </ul>
              </div>
            </div>
            <div className="payment">
              <p>
                <span>Sub Total:</span>
                <span>${invoice?.price}</span>
              </p>
              <p>
                <span>Tax:</span>
                <span>${invoice?.tax}</span>
              </p>
              <p className="total">
                <span>Total:</span>
                <span>
                  $
                  {parseFloat(
                    parseFloat(invoice?.price) + parseFloat(0)
                  ).toFixed(2)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </InvoiceCont>
    );
  };
  const CartItem = ({ item }) => {
    const { rotation, filter } = item;
    let index;

    if (rotation == "90" || rotation == "270") {
      index = {
        w: 1,
        h: 0,
      };
    } else {
      index = {
        w: 0,
        h: 1,
      };
    }

    let dime = item?.dim?.split("x");
    let ratio = dime[0] / dime[1];
    let width = 300;
    let height = ratio * width;
    let ibd = item?.ibd?.split("x");
    let obd = item?.obd?.split("x");
    if (ibd && obd) {
      ibd[0] = parseInt(ibd[0]);
      ibd[1] = parseInt(ibd[1]);
      obd[0] = parseInt(obd[0]);
      obd[1] = parseInt(obd[1]);
      ibd[0] = (ibd[0] / obd[0]) * width;

      ibd[1] = (ibd[1] / obd[1]) * height;
      obd[0] = width;
      obd[1] = height;
    }
    const onCancelHandler = async (id) => {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + "/order/v1/orderStatus",
        {
          orderStatus: "cancelled",
          id,
        },
        {
          headers: {
            "x-token": localStorage.getItem("accessToken"),
            "x-refresh-token": localStorage.getItem("refreshToken"),
          },
        }
      );
      if (res.data.data) {
        toast.success("Order Cancelled");
        window.location.reload();
      } else {
        toast.error(res.data.data.msg);
      }
    };

    const statusComponent = (status) => {
      let comp;
      switch (status) {
        case "cancelled":
          comp = (
            <div className="status-view">
              <p className="cancel-text">Cancelled</p>
            </div>
          );
          break;
        case "refunded":
          comp = (
            <div className="status-view">
              <p className="cancel-text">Refunded</p>
            </div>
          );
          break;
        case "pending":
          comp = (
            <div className="status-view">
              <div>
                <StatusBar content={"Approved"} active={false}>
                  <div className="activeline"></div>
                </StatusBar>
                <StatusBar content={"Shipped"} active={false}>
                  <div className="activeline"></div>
                </StatusBar>
                <StatusBar content={"Delivered"} active={false}>
                  <div className="activeline"></div>
                </StatusBar>
              </div>
              <div className="footer">
                <button
                  onClick={() => modifyOrderHandler(item)}
                  className="mod-btn"
                >
                  Update Address
                </button>
                <button
                  onClick={() => onCancelHandler(item.oid)}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          );
          break;
        default:
          comp = (
            <div className="status-view">
              <div className="bar">
                <StatusBar
                  content={"Approved"}
                  active={
                    ["approved", "shipped", "delivered"].includes(status)
                      ? true
                      : false
                  }
                >
                  <div className="activeline"></div>
                </StatusBar>
                <StatusBar
                  content={"Shipped"}
                  active={
                    ["shipped", "delivered"].includes(status) ? true : false
                  }
                >
                  <div className="activeline"></div>
                </StatusBar>
                <StatusBar
                  content={"Delivered"}
                  active={["delivered"].includes(status) ? true : false}
                >
                  <div className="activeline"></div>
                </StatusBar>
              </div>
            </div>
          );
      }
      return comp;
    };

    return (
      <>
        {addressModal && (
          <AuthModal onClose={() => setAddressModal(false)}>
            <ListAddresses
              isModal
              closeModal={() => setAddressModal(false)}
              setSelectedAddress={(e) => {
                updateAddressHandler(e.id);
              }}
            />
          </AuthModal>
        )}
        <CartItemView
          bgImg={mediaEndPoint + "/frames/" + item?.frame_key}
          height={height}
          width={width}
          innerHeight={ibd && ibd[index.h]}
          innerWidth={ibd && ibd[index.w]}
        >
          <div className="item-img">
            <img
              className="frameImg"
              src={`${mediaEndPoint}/frames/${item.frame_key}`}
              alt="frame"
              style={{ transform: `rotate(${rotation}deg)` }}
            />{" "}
            <img
              className={`img ${filter ? filter : ""}`}
              src={`${mediaEndPoint}/posters/${item.poster_key}`}
              alt="finalImg"
            />
          </div>

          <div className="content">
            <div className="item-info">
              <div>
                <div className="item-name">{item?.dsc}</div>
                <div>
                  Size: {sizeList[item?.size]} ({item?.dim})
                </div>
              </div>
              <div className="item-view">
                <div className="info">
                  <span className="trId">ORDERED ON: 10th Aug 2022</span>
                  <span className="trId">ORDER ID : {item?.oid}</span>
                  <span className="trId">DELIVERY BY: 15th August</span>
                  <button
                    className="item-view-btn"
                    onClick={(e) => handleViewClick(item)}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div>
            {statusComponent(item.orderStatus)}
          </div>
        </CartItemView>
      </>
    );
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return loading ? (
    <Loader message="Fetching your orders!" />
  ) : (
    <Container>
      {creatingInvoice && <Loader absolute message="Downloading Invoice!" />}
      {invoice && <Invoice />}
      <h1 class="heading">My Orders</h1>
      <OrderCont>
        {orders.map((item) => (
          <CartItem key={item.oid} item={item} />
        ))}
        {orders?.length === 0 && <Nothing />}
      </OrderCont>
    </Container>
  );
};

export default Order;
