import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toast";

const AuthProvider = ({ children, ...props }) => {
  let location = useLocation();
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refreshToken");
  try {
    let decoded2 = jwt_decode(refreshToken);
    console.log(decoded2);
  } catch (e) {
    console.log(e);
    toast.error("Session Expired! Redirecting to Login");
    localStorage.clear();
  }
  if (token && refreshToken) {
    return children;
  }
  // props.setLoginModal(true);
  return <Navigate to={"/?login=true"} state={{ from: location }} replace />;
};

export default AuthProvider;
