import { device } from "assets/theme/device";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 60px;
  padding: 50px;
  @media ${device.mobileL} {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  width: 100%;
  overflow-x: hidden;

  .heading {
    color: #26a48e;
    align-self: flex-start;
  }
`;

export const CartCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 4em;
  max-width: 90%;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 80em;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

export const InvoiceCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5em;
  width: 50em;

  .company-info {
    width: 100%;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 8em;
    }
  }
  .invoice-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    .invoice-body-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .invoice-body-top-left {
        width: 48%;
        display: flex;
        flex-direction: column;
      }
      .invoice-body-top-right {
        display: flex;
        flex-direction: column;
      }
    }
    .invoice-body-bottom {
      display: flex;
      flex-direction: column;
      width: 100%;
      .details {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .order-details {
        display: absolute;
        left: 0;
        gap: 2em;
        height: 8em;
        align-items: center;
        justify-content: space-around;
        .order-details-left {
          margin-top: 2em;
          margin-bottom: 3em;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: inset 0px 0px 10px rgba(245, 129, 0, 0.1);
          padding: 0px;
          width: ${(props) => (props.width ? props.width : 250)}px;
          height: ${(props) => props.height}px;
          position: relative;
          .frameImg {
            width: 100%;
            height: 100%;
            position: absolute;
          }
          .img {
            width: ${(props) => props.innerWidth}px;
            height: ${(props) => props.innerHeight}px;
            transform: scale(1);
            transform-origin: 0 0;
          }
        }
        ul {
          margin-top: -10px;
          margin-left: -10px;
        }
      }
      .order-details-right {
        margin-left: 20em;
        margin-top: -4em;
        gap: 1em;
      }
      .payment {
        width: 30%;
        margin-left: auto;
        margin-top: 3em;
        p {
          display: flex;
          justify-content: space-between;
          padding: 1em;
          margin: 0;
        }
        .total {
          background-color: #000;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
  }
`;

export const OrderCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 2em;
  margin-bottom: 2em;
  gap: 1.5em;
`;

export const CartItemView = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 1.5em;
  @media ${device.mobileM} {
    padding: 0em;
  }
  border-radius: 10px;
  gap: 2em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:hover {
    box-shadow: rgb(38 166 154 / 55%) 0px 0px 10px 0px;
  }

  .item-view-btn {
    display: flex;
    align-self: flex-start;
    margin-top: 3em;
  }

  .item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 10px rgba(245, 129, 0, 0.1);
    padding: 0px;
    width: ${(props) => (props.width ? props.width : 250)}px;
    height: ${(props) => props.height}px;
    margin-top: 2em;
    margin-bottom: 2em;
    @media ${device.mobileM} {
      margin-top: 2em;
    }
    position: relative;
    .frameImg {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .img {
      width: ${(props) => props.innerWidth}px;
      height: ${(props) => props.innerHeight}px;
      transform: scale(1);
      transform-origin: 0 0;
      margin: 0px;
    }
  }
  .status-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media ${device.mobileM} {
      gap: 30px;
    }
    .bar {
      margin-right: 5.5em;
    }
    .cancel-text {
      font-size: 1.2em;
      color: red;
      margin-top: 2em;
      font-weight: 600;
    }
    .mod-btn {
      font-size: 1em;
      cursor: pointer;
      border: none;
      background-color: orange;
      color: #fff;
      padding: 0.5em 1em;
      border-radius: 5px;
    }
    .cancel-btn {
      font-size: 1em;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: red;
      padding: 0.5em 1em;
      border-radius: 5px;
      border: 1px solid red;
      margin-left: 1em;
    }
    .footer {
      margin-bottom: 3em;
      display: flex;
      @media ${device.mobileM} {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2em;
    max-width: 50%;
    .item-name {
      font-size: 1.5em;
      color: #000;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.5em;
      @media ${device.mobileM} {
        text-align: center;
      }
    }
    div {
      font-size: 1em;
      color: #07806b;
      margin-bottom: 1em;
      @media ${device.mobileM} {
        text-align: center;
      }
    }
    .item-price {
      font-weight: 600;
    }
  }
  .content {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    display: flex;
    flex-direction: row;
    @media ${device.mobileM} {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  .item-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    margin-right: 2em;
    overflow-x: hidden;
    span {
      font-size: 1em;
      color: #07806b;
      font-weight: 600;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .trId {
      text-align: left;
      width: 100%;
      overflow: hidden;
      line-height: 1.5em;
    }
    button {
      background-color: #07806b;
      color: #fff;
      border-radius: 10px;
      border: none;
      padding: 0.5em 1em;
      font-size: 1em;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      cursor: pointer;
      transition: all 0.2s linear;
      &:active {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
      }
      @media ${device.mobileM} {
        display: flex;
        justify-content: center;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: 2em;
    gap: 1.5em;
    button {
      background-color: red;
      color: #fff;
      border-radius: 5px;
      padding: 0.75em;
      padding-inline: 1em;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 0.9em;
      &:active {
        transform: scale(0.99);
      }
    }
    .order {
      background-color: ${(props) => props.theme.main};
    }
  }
  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;
    padding-top: 1.5em;
    padding-left: 0em;
    gap: 1em;
    margin-bottom: 0;
    border-radius: 1em;
    .status-view {
    }
    .item-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-info {
      width: 90%;
      max-width: 100%;
    }
    .item-view {
      max-width: 100%;
      width: 100%;
      span,
      .trId {
        width: 90%;
      }
    }
    .buttons {
      width: 100%;
      flex-direction: row;
      margin-top: 1em;
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
    }
  }
`;

export const StatusBar = styled.div`
  .activeline {
    background-color: ${(props) => (props.active ? "#07806b" : "#D3D3D3")};
    width: 4px;
    border-radius: 4px;
    z-index: 0;
    margin-right: 10px;
    height: 40px;
    margin-top: 30px;
    font-size: 1.2em;
    color: ${(props) => (props.active ? "#07806b" : "#D3D3D3")};
    font-weight: 600;
  }

  .activeline::before {
    z-index: 1;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: ${(props) => (props.active ? "#07806b" : "#D3D3D3")};
    transform: translateY(-25px) translateX(-5px);
  }
  .activeline::after {
    z-index: 1;
    content: "${(props) => props.content}";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    transform: translateY(-50px) translateX(20px);
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
  @media ${device.tablet} {
    display: block;
  }
`;

export const LeftCardContainer = styled.div`
  width: 100%;

  .userInfo {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1em;
    background-color: #f2f2f2;
    font-weight: 600;

    p {
      margin-bottom: 0;
      margin-left: 30px;
      padding-top: 20px;
    }

    .info {
      width: 100%;
      display: flex;
      gap: 1em;
      margin-top: 0;
      margin-left: 30px;
      padding-bottom: 5px;
      padding-right: 30px;
      font-weight: 400;

      .name {
        display: flex;
        gap: 3em;
        width: 340px;
        @media ${device.laptop} {
          width: 170px;
        }
        @media ${device.tablet} {
          width: 340px;
        }
      }

      .address {
        width: 340px;
        @media ${device.laptop} {
          width: 170px;
        }
        @media ${device.tablet} {
          width: 340px;
        }
      }
    }

    button {
      margin-left: 140px;
      margin-right: 30px;
      background-color: #26a486;
      border-radius: 80px;
      color: white;
      height: 30px;
      min-width: 100px;
      border: 0;
      font-size: 0.7em;
      margin-top: -20px;
      cursor: pointer;
      outline-offset: 0px;
      margin-bottom: 20px;
    }

    .payment {
      padding-bottom: 20px;
    }
  }
`;

export const RightCardContainer = styled.div`
  margin-top: 16px;
  display: block;
  width: 100%;
  justify-content: center;
  padding-top: 40px;
  background-image: linear-gradient(to right, #26a48643, #e4872027);
  border-radius: 8px;
  padding-bottom: 20px;

  p {
    margin-left: 100px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2em;
    font-family: Montserrat;
    font-weight: 900;
    background: linear-gradient(to right, #26a48680, #e48720 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  hr {
    margin-bottom: 20px;
    margin-left: 100px;
    margin-top: 20px;
    width: 70%;
    border-top: 2px solid gray;
  }

  h5 {
    color: black;
    font-size: 0.9em;
    padding-left: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 150px;
  }

  h1 {
    margin-left: 100px;
  }

  .totalprice {
    margin-left: 165px;
  }

  .del-price {
    margin-left: 206px;
  }
  .disc-price {
    margin-left: 200px;
  }
  .paybtn {
    padding: 5% 12%;
    .pay {
      font-size: 1.4em;
      color: #fff;
      background-image: linear-gradient(to right, #26a48e70, #e4872060);
      width: 100%;
      padding: 15px 30px;
      border: 0;
      border-radius: 40px;
      cursor: pointer;
    }
  }
`;

export const CartItemView2 = styled.div`
  display: flex;
  // flex-direction: column;
  // flex: 0 0 calc(33.333333% - 2.66666em);
  background-color: #26a48e10;
  // padding: 1.5em;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  margin-bottom: 2em;
  max-height: 100%;

  @media ${device.tablet} {
    display: block;
  }

  // &:hover {
  //   box-shadow: rgb(38 166 154 / 55%) 0px 0px 10px 0px;
  // }

  .image-content {
    display: flex;
  }

  .item-img {
    padding-bottom: 30px;
    margin-top: 2em;
    margin-left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 10px rgba(245, 129, 0, 0.1);
    width: ${(props) => (props.width ? props.width : 250) / 3}px;
    height: ${(props) => props.height / 3}px;
    @media ${device.mobileM} {
      margin-top: 1.5em;
    }
    position: relative;
    .frameImg {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .img {
      width: ${(props) => props.innerWidth / 3}px;
      height: ${(props) => props.innerHeight / 3}px;
      transform: scale(1);
      transform-origin: 0 0;
      margin: 0px;
    }
  }
  .item-info {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-top: 1.3em;
    min-width: 250px;

    .item-name {
      font-size: 1em;
      font-family: Montserrat;
      color: black;
      font-style: normal;
      font-weight: 900 !important;
    }
    div {
      font-style: normal;
      font-size: 0.8em;
      font-weight: 700 !important;
      color: #07806b;
    }
    .item-price {
      color: black;
      margin-top: 10px;
      font-size: 1.1em;
      font-weight: 900;
    }

    .delivery {
      font-size: 0.7em;
      margin-bottom: 20px;
      color: gray;
    }
  }
  .item-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 2em;
    overflow-x: hidden;
    max-width: 20%;
    span {
      font-size: 1em;
      color: #9a9a9a;
      margin-bottom: 1em;
      font-weight: 600;
    }
    .trId {
      width: 100%;
      overflow: hidden;
      word-break: break-all;
    }
    button {
      background-color: ${(props) => props.theme.secondary};
      color: #fff;
      border-radius: 10px;
      border: none;
      padding: 0.5em 1em;
      font-size: 1em;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      cursor: pointer;
      transition: all 0.2s linear;
      &:active {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
      }
    }
  }
  
  .btnSection{
    @media ${device.tablet} {
      display: flex;
      width: 150px;
    }
  }

  .removeButton {
    display: flex;
    margin-top: 1em;
    gap: 1.5em;
    margin-bottom: 1em;
    margin-left: 100px;
    margin-right: 30px;
    @media ${device.tablet} {
      margin-left: 100px;
      padding-bottom: 20px;
    }

    img {
      cursor: pointer;
    }

    button {
      
      background-color: #26a48e90;
      color: #fff;
      border-radius: 90px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 0.9em;
      min-width: 7em;
      max-height: 2.2em;
    }
    }

    .checkoutButton {
      display: flex;
      margin-top: 1em;
      gap: 1.5em;
      margin-bottom: 1em;
      margin-left: 30px;
      margin-right: 30px;
      @media ${device.tablet} {
        margin-left: 10px;
        padding-bottom: 20px;
      }
      button {
        background-color: #ff000090;
        color: #fff;
        border-radius: 90px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        // padding-inline: 1em;
        border: none;
        cursor: pointer;
        font-weight: 600;
        font-size: 0.9em;
        min-width: 7em;
        max-height: 2.2em;
      }
      }

  }
`;

export const Address = styled.div`
  display: flex;
  cursor: ${(props) => (props.fromProfile ? "default" : "pointer")};
  margin-bottom: 1em;
  flex-direction: column;
  color: black
  &:hover {
    opacity: ${(props) => (props.fromProfile ? "1" : "0.5")};
  }
  .row {
    display: flex;
  }
  .cityState {
    display: flex;
    gap: 3em;
  }
  @media ${device.mobileM} {
    width: 100%;
  }
`;

export const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  @media ${device.mobileM} {
    width: 100%;
  }
`;
