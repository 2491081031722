import { testimonials } from "assets/static/testimonials";
import { device } from "assets/theme/device";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import test1 from "assets/images/testi1.png";
import testi2 from "assets/images/testi2.png";

const Testimonials = () => {
  const [selectedTestimonials, setSelectedTestimonials] = React.useState(0);
  const [shouldRender, setShouldRender] = React.useState("");

  const handleArrowClick = (direction) => {
    setShouldRender(direction);
  };

  const handleNext = () => {
    setSelectedTestimonials((selectedTestimonials + 1) % testimonials.length);
  };
  const handlePrev = () => {
    setSelectedTestimonials(
      (selectedTestimonials - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <Container>
      <span className="title">Testimonials</span>
      <div className="testimonials">
        <span onClick={(e) => handlePrev()} style={{ marginBottom: "3em" }}>
          <ArrowBackIosNewOutlined color="secondary" />
        </span>
        <Testimonial>
          <img src={testimonials[selectedTestimonials].img} alt="testimonial" />
          <div className="testimonial">
            <div className="testimonialText">
              <span>"</span>
              {testimonials[selectedTestimonials].testimonial}
              <span>"</span>
            </div>
            <div className="name">
              {testimonials[selectedTestimonials].name}
            </div>
            <div className="location">
              {testimonials[selectedTestimonials].location}
            </div>
          </div>
        </Testimonial>
        <span onClick={(e) => handleNext()}>
          <ArrowForwardIosOutlined
            color="secondary"
            style={{ marginBottom: "2em" }}
          />
        </span>
      </div>
      <div
        className="showcase"
        style={{ marginBottom: "8em", marginTop: "8em" }}
      >
        <img src={test1} className="testi1" alt="testi1" />
        <img src={testi2} className="testi2" alt="testi2" />
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 7em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  .title {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 2em;
    margin-bottom: 1em;
    color: rgb(38, 166, 154);
  }
  .testimonials {
    position: relative;
    display: flex;
    align-items: center;
    width: 74em;
    max-width: 100%;
    gap: 1em;
    svg {
      cursor: pointer;
    }
  }
  .showcase {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1em;
    height: 750px;
    //grid-template-areas: "testi1 testi1 testi2 testi2 testi2 testi2";
    .testi1 {
      width: 35%;
      height: 100%;
    }
    .testi2 {
      //grid-area: testi2;
      width: 55%;
      height: 100%;
    }
  }
`;

const Testimonial = styled.div`
  display: flex;
  width: 70em;
  max-width: 96%;
  padding: 2em;

  gap: 1em;
  border-radius: 10px;
  color: #000;
  margin-bottom: 2em;
  img {
    width: 30%;
    border-radius: 40px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  }
  .testimonial {
    background: linear-gradient(
      89.91deg,
      rgba(38, 164, 142, 0.21) 1.18%,
      rgba(228, 135, 32, 0.21) 98.05%
    );
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    div {
      font-size: 0.875em;
      color: rgba(0, 0, 0, 0.53);
    }
    .testimonialText {
      font-size: 1.1em;
      font-weight: 400;
      margin-bottom: 2em;
      font-family: "Montserrat";
      font-weight: 400;
      color: rgba(0, 0, 0, 0.53);
      span {
        font-size: 1.5em;
        color: rgba(0, 0, 0, 0.53);
      }
    }
  }
  @media ${device.mobileM} {
    width: 99%;
    flex-direction: column;

    img {
      width: 100%;
    }
    background: linear-gradient(0deg, #fff -10%, rgb(38, 166, 154, 0.7));
  }
`;

export default Testimonials;
