import { device } from "assets/theme/device";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: url(${(props) => props.back});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 170px;
  padding-top: 6em;

  p {
    color: ${(props) => props.theme.tertiary};
    font-size: 30px;

    /* line-height: 69px; */

    width: 460px;
    max-width: 95%;
    text-align: center;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 50%);
    font-weight: 700;
    margin-top: 400px;
    margin-bottom: 520px;
    font: "Montserrat";
    text-transform: uppercase;

    @media ${device.mobileM} {
      font-size: 20px;
    }
  }
  span {
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    width: 550px;
    max-width: 95%;
    margin-top: 30px;

    color: rgba(0, 0, 0, 0.5);
  }
  @media ${device.mobileM} {
    background-size: cover;
  }
`;

export const UploadButton = styled.button`
  font-family: "Montserrat";
  background-color: #26a48e20;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
  border: 3px solid #26a48e60;
  color: #00000040;
  height: 5em;
  width: 30em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  input {
    display: none;
  }
  label {
    width: 100%;
    height: 100%;
    font-size: 36px;
    padding: 16px;
    padding-inline: 20px;
    cursor: pointer;
    font-weight: 400;
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 10%),
      inset 0px 0px 10px rgba(0, 0, 0, 10%);
  }
`;

export const ImageButton = styled.button`
  font-family: "Montserrat";
  margin-top: -2em;
  background-color: #26a48e;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
  border: 3px solid #26a48e60;
  color: #ffffff;
  height: 3.5em;
  width: 20em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  input {
    display: none;
  }
  label {
    width: 100%;
    height: 100%;
    font-size: 24px;
    padding: 5px;
    cursor: pointer;
    font-weight: 400;
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 10%),
      inset 0px 0px 10px rgba(0, 0, 0, 10%);
  }
`;
