import {
  addNewAddress,
  getAddresses,
  getAutoComplete,
  deleteAddress,
} from "apis/order/order";
import Loader from "Components/Loader/Loader";
import React, { useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import { states } from "assets/static/stateCity";
import { device } from "assets/theme/device";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import AsyncSelect from "react-select/async";
import DeleteIcon from "@mui/icons-material/Delete";

const ListAddresses = (props) => {
  const [addresses, setAddresses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState({
    label: "Select State",
    value: null,
  });
  const [city, setCity] = React.useState({ label: "Select City", value: null });
  const [zip, setZip] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const stateList = Object.keys(states).map((state) => ({
    value: state,
    label: state,
  }));
  const [cityList, setCityList] = React.useState([]);
  const [queryList, setQueryList] = React.useState([]);
  const [showAddAddress, setShowAddAddress] = React.useState(false);

  const fetchAddresses = async () => {
    const response = await getAddresses();
    if (response && response.success) setAddresses(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const addAddress = async () => {
    if (loading || !state.value || !city.value || !zip || !address || !phone) {
      alert("Please fill all the details!");
      return;
    }
    setLoading(true);
    const body = {
      state: state.value,
      city: city.value,
      zipcode: zip,
      address: address,
      phone: phone,
    };
    const response = await addNewAddress(body);
    if (response && response.success) {
      fetchAddresses();
      setState({ label: "Select State", value: null });
      setCity({ label: "Select City", value: null });
      setZip("");
      setAddress("");
      setPhone("");
    }
  };

  const promiseOptions = async (value) => {
    if (value.length % 4 === 0 && value.length !== 0) {
      const { data } = await getAutoComplete(value);
      if (data.length !== 0) {
        setQueryList(data);
        return data.map((item) => ({
          label: item.label,
          value: item.label,
        }));
      }
    }
  };

  const addressSelectHandler = (e) => {
    const selected = queryList.filter((item) => item.label === e.label);
    if (selected.length !== 0) {
      const { city, state, postalCode, label, street } = selected[0];
      setAddress(street);
      setCity({ value: city, label: city });
      setState({ value: state, label: state });
      setZip(postalCode);
    }
  };

  const deleteAddressHandler = async (id) => {
    const response = await deleteAddress(id);
    if (response && response.success) fetchAddresses();
  };

  const AddressShow = (prop) => {
    return (
      <div
        style={{
          border: `${
            prop.address.is_default ? "1px" : "0px"
          } solid rgb(38, 166, 154, 0.7)`,
          padding: 13,
          paddingInline: 20,
          paddingBottom: 0,
          marginBottom: "20px",
          borderRadius: 5,
        }}
      >
        <Address
          fromProfile={props.fromProfile}
          onClick={() => {
            if (props.setSelectedAddress)
              props.setSelectedAddress(prop.address);
            if (props.closeModal) props.closeModal();
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className="row">
                {prop.address.address}, {prop.address.city},{" "}
                {prop.address.state}
              </div>
              <div className="cityState">
                <span>{prop.address.zipcode}</span>
              </div>
            </div>
            <DeleteIcon
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => deleteAddressHandler(prop.address.id)}
            />
          </div>
        </Address>
        {prop.address.is_default && (
          <p
            style={{
              color: "rgb(38, 166, 154, 0.7)",
              fontStyle: "italic",
              fontWeight: 600,
              lineHeight: 0,
              fontSize: 14,
            }}
          >
            Default
          </p>
        )}
      </div>
    );
  };

  return loading ? (
    <Loader message="Fetching Adresses!" />
  ) : (
    <Container>
      {props.fromProfile || addresses.length === 0 ? (
        ""
      ) : (
        <Header>Select an Address</Header>
      )}

      {addresses.length !== 0 && (
        <Addresses>
          {showAddAddress
            ? addresses.slice(0, 2).map((thisAddress) => {
                return (
                  <AddressShow key={thisAddress.id} address={thisAddress} />
                );
              })
            : addresses.map((thisAddress) => {
                return (
                  <AddressShow key={thisAddress.id} address={thisAddress} />
                );
              })}
        </Addresses>
      )}

      <span className="addCont">
        {showAddAddress ? (
          <RemoveCircleOutlineOutlinedIcon
            onClick={() => setShowAddAddress(false)}
            style={{ color: "#07806B", cursor: "pointer" }}
          />
        ) : (
          <AddCircleOutlineOutlinedIcon
            onClick={() => setShowAddAddress(true)}
            style={{ color: "#07806B", cursor: "pointer" }}
          />
        )}
        <span className="heading">ADD ADDRESS</span>
      </span>

      {showAddAddress && (
        <AddAddress>
          <div className="form">
            <AsyncSelect
              placeholder="Select Address"
              cacheOptions
              defaultOptions
              loadOptions={promiseOptions}
              onChange={addressSelectHandler}
            />
            <input
              type="text"
              placeholder="Pincode*"
              onChange={(e) => {
                if (e.target.value.length < 6) setZip(e.target.value);
              }}
              value={zip}
              name={"zip"}
            />
            <input
              type="text"
              placeholder="Address (House No, Street, Area)*"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              value={address}
              name={"address"}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Select
                isSearchable
                isClearable
                onChange={(e) => {
                  if (!e || !e.value) {
                    setState({ label: "Select State", value: null });
                    return;
                  }
                  setState(e);
                  setCity({ value: null, label: "Select City" });
                  setCityList(
                    states[e.value].map((cities) => ({
                      value: cities,
                      label: cities,
                    }))
                  );
                }}
                styles={{ width: "100%" }}
                name="state"
                value={state}
                options={stateList}
              />
              <Select
                styles={{ width: "100%" }}
                isSearchable
                isClearable
                onChange={(e) => {
                  setCity(e);
                }}
                name="city"
                value={city}
                options={cityList}
                isDisabled={!state.value}
              />
            </div>

            <input
              type="text"
              placeholder="Phone*"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              name={"phone"}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="h7">
                  Make this my default address
                </Typography>
              }
            />
            <button onClick={(e) => addAddress()}>ADD</button>
          </div>
        </AddAddress>
      )}
    </Container>
  );
};

const Or = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1rem 0;
`;

const AddAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 2em;
    gap: 1em;
    @media ${device.mobileM} {
      padding: 0;
    }

    input {
      width: 100%;
      padding: 1em;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
      &:focus {
        border: 1px solid ${(props) => props.theme.main};
      }
    }
    input::placeholder {
      font-size: 16px;
    }
    button {
      width: 30%;
      padding: 1em;
      border: 1px solid #ccc;
      border-radius: 30px;
      outline: none;
      background: rgb(38, 166, 154);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-inline: 0;
      &:active {
        transform: scale(0.99);
      }
    }
  }
`;
const Address = styled.div`
  display: flex;
  padding: 0.3em;
  border-radius: 4px;
  background-color: rgb(38, 166, 154, 0.7);
  cursor: ${(props) => (props.fromProfile ? "default" : "pointer")};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding-inline: 1em;
  padding-block: 1em;
  flex-direction: column;
  color: ${(props) => props.theme.main};
  &:hover {
    opacity: ${(props) => (props.fromProfile ? "1" : "0.5")};
  }
  .row {
    display: flex;
    color: #fff;
  }
  .cityState {
    display: flex;
    gap: 3em;
    margin-top: 0.5em;
    color: #fff;
  }
  @media ${device.mobileM} {
    width: 100%;
  }
`;

const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0.5em;
  @media ${device.mobileM} {
    width: 100%;
    padding-inline: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
  padding-top: 1em;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 40em;
  &::-webkit-scrollbar {
    width: 0px;
  }

  .addCont {
    align-self: flex-start;
    display: flex;
    align-items: center;
  }

  .heading {
    font-size: 1rem;
    margin-left: 1.2em;
    color: RGB(126, 130, 122);
    font-weight: 600;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media ${device.mobileM} {
    width: 100%;
    padding-inline: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 2em;
  font-size: 1.2em;
  font-weight: 500;
  font-weight: 600;
`;

export default ListAddresses;
