import { baseAddApi } from "apis/constants";
import { toast } from "react-toast";
import axiosApiInstance from "../axiosSetup";

const backendUrl = baseAddApi;
const backendUrl2 = process.env.REACT_APP_API_URL;

export const makePayment = async (body) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl2 + "/payment/v1/createPayment",
      body,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to add address!");
  }
};

export const makeGiftCardPayment = async (body) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl2 + "/payment/v1/createPayment/giftcard",
      body,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(
      error.response?.data?.msg || "Failed to create gift card payment!"
    );
  }
};
