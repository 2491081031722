import { mediaEndPoint, sizeList } from "apis/constants";
import { getLargeFrames } from "apis/frames/frames";
import Loader from "Components/Loader/Loader";
import Nothing from "Components/Nothing/Nothing";
import React from "react";
import { toast } from "react-toast";
import { Container, FrameCont } from "../Small/Small.styles";
import stockRect from "assets/images/icons/stockRect.png";
import stockSquare from "assets/images/icons/stockSquare.png";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import Select from "react-select";
import { filterValues } from "../../../Components/Filter/values";
import "../../../Components/Filter/filter.css";
import { useDispatch } from "react-redux";
import { frameReducer } from "../../../redux/features/frame";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Large = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState({
    label: "Apply Filter",
    value: null,
  });
  const [rotation, setRotation] = React.useState(0);
  const [index, setIndex] = React.useState({ width: 0, height: 1 });

  const [frames, setFrames] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [imgUrl, setImageUrl] = React.useState(
    localStorage.getItem("finalImgUrl") || null
  );

  const fetchFrames = async () => {
    const largeFrames = await getLargeFrames();
    if (largeFrames && largeFrames?.success) {
      let unSortedFrames = [...largeFrames?.data];
      let sortedFrames = {};
      unSortedFrames.map((fram) => {
        return (sortedFrames[fram.dim] = [
          ...(sortedFrames[fram.dim] || []),
          fram,
        ]);
      });

      setFrames(sortedFrames);
    }
    setLoading(false);
  };

  const rotateFrame = () => {
    setRotation((rotation + 90) % 360);
    const size = { ...index };
    let temp = size.width;
    size.width = size.height;
    size.height = temp;
    setIndex(size);
  };

  const FrameShow = ({ frame }) => {
    let dime = frame?.dim?.split("x");
    let ratio = dime[0] / dime[1];
    let width = 300;
    let height = ratio * width;
    let ibd = frame?.ibd?.split("x");
    let obd = frame?.obd?.split("x");
    let bwidth = 20;
    if (ibd && obd) {
      ibd[0] = parseInt(ibd[0]);
      ibd[1] = parseInt(ibd[1]);
      obd[0] = parseInt(obd[0]);
      obd[1] = parseInt(obd[1]);
      console.log(ibd, obd, width);
      ibd[0] = (ibd[0] / obd[0]) * width;

      ibd[1] = (ibd[1] / obd[1]) * height;
      obd[0] = width;
      obd[1] = height;
      console.log(ibd, obd, width);
      bwidth = (parseInt(obd[0]) - parseInt(ibd[0])) / 2;
    }

    const onNavigateCheckout = () => {
      dispatch(
        frameReducer({ id: frame.id, size: "small", rotation, filter, index })
      );
      navigate(`/checkout`);
    };

    return (
      <FrameCont
        height={height}
        width={width}
        innerHeight={ibd && ibd[index.height]}
        innerWidth={ibd && ibd[index.width]}
      >
        <div className="frame" onClick={onNavigateCheckout}>
          <img
            className="frameImg"
            src={`${mediaEndPoint}/frames/${frame.key}`}
            alt="frame"
            style={{ transform: `rotate(${rotation}deg)` }}
          />
          <img
            className={`img ${filter.value}`}
            src={
              imgUrl ||
              frame.fimg ||
              (ratio > 0.9 && ratio < 1.1 ? stockSquare : stockRect)
            }
            alt="finalImg"
          />
        </div>
        <div className="info">
          <span className="desc">{frame?.dsc}</span>
          <p className="size">
            <span>{sizeList[frame?.size]}</span> &nbsp; (
            <span>{frame?.dim}</span>)
          </p>
          <span className="rotateBtn" onClick={rotateFrame}>
            ROTATE
            <RefreshIcon />
          </span>
          <span className="price">{frame?.price} $</span>
        </div>
      </FrameCont>
    );
  };

  React.useEffect(() => {
    fetchFrames();
  }, []);

  let allItems = [];

  Object.keys(frames).map(
    (frameKey) => (allItems = allItems.concat(frames[frameKey]))
  );

  return loading ? (
    <Loader message="Fetching frames! Hold still!" />
  ) : (
    <Container>
      {Object.keys(frames).length > 0 && (
        <div className="filterCont">
          <Select
            placeholder="Apply Filter"
            isSearchable
            isClearable
            onChange={(e) => setFilter({ label: e.label, value: e.value })}
            name="class"
            value={filter}
            options={filterValues}
          />
        </div>
      )}
      {Object.keys(frames).length > 0 ? (
        <div className="sep">
          {allItems.map((frame) => (
            <FrameShow key={frame?.id} frame={frame} />
          ))}
        </div>
      ) : (
        <Nothing />
      )}
    </Container>
  );
};

export default Large;
