import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { makeGiftCardPayment } from "../../apis/payment/payment";
import { toast } from "react-toast";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { useNavigate } from "react-router-dom";
import { createGiftCardOrder } from "apis/order/order";
import logo from "assets/images/logo.png";

ReactGA.pageview(window.location.pathname);

const GiftCards = () => {
  const navigate = useNavigate();

  const currentDate = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.toLocaleString("default", { month: "long" });
    const year = today.getFullYear();

    let suffix;
    if (date === 1 || date === 21 || date === 31) {
      suffix = "st";
    } else if (date === 2 || date === 22) {
      suffix = "nd";
    } else if (date === 3 || date === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    const formattedDate = `${date}${suffix} ${month} ${year}`;
    return formattedDate;
  };

  const [showPaymentForm, setShowPaymentForm] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState(null);

  const buyCardHandler = async (gid, price) => {
    setSelectedCard({ gid, price });
    setShowPaymentForm(true);
  };

  const cards = [
    {
      gid: 1,
      price: 20,
    },
    {
      gid: 2,
      price: 50,
    },
    {
      gid: 3,
      price: 10,
    },
  ];

  const cardNonceResponseReceived = async () => {
    let body = {
      gid: selectedCard.gid,
      value: selectedCard.price,
      userId: 1,
    };
    let resp = await createGiftCardOrder(body);
    if (resp && resp?.success) {
      toast.success("Order created!");
      navigate("/my-gift-cards");
    } else {
      setShowPaymentForm(false);
      toast.error("Failed to create order!");
    }
    setShowPaymentForm(false);
  };

  return (
    <>
      <Container>
        <h1 className="title">About Momento</h1>
        <div className="content">
          <p>
            Momento is the result of having dressed the artwork of our thousands
            of clients during the past 40 years. We want to bring our expertise
            and craftsmanship who is looking for something special at affordable
            prices
          </p>
        </div>
        <div className="grid">
          {cards.map((card) => (
            <Card key={card.gid}>
              <h1 className="rupee">${card.price} Gift Card</h1>
              <button onClick={() => buyCardHandler(card.gid, card.price)}>
                Buy Now
              </button>
            </Card>
          ))}
        </div>
      </Container>
      {showPaymentForm && (
        <PaymentModal onClick={(e) => setShowPaymentForm(false)}>
          <div
            className="inner"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PaymentForm
              // applicationId={process.env.REACT_APP_SANDBOX_APPLICATION_ID}
              // locationId={process.env.REACT_APP_SANDBOX_LOCATION_ID}
              applicationId="sandbox-sq0idb-i7_e-75TTAulfT4X6q3xgg"
              locationId="BRHN2SWCJYMBH"
              cardTokenizeResponseReceived={async (token, buyer) => {
                const paymentbody = {
                  gid: selectedCard.gid,
                  price: selectedCard.price,
                  sourceId: token.token,
                };
                const response = await makeGiftCardPayment(paymentbody);
                await cardNonceResponseReceived(response.data.id);
                console.log(response);
                toast.success("Payment Successful");
              }}
            >
              <>
                <PaymentDetails>
                  <div>
                    <p>Payment Details</p>
                    <h2>${selectedCard.price} Gift Card</h2>
                    <p>{currentDate()}</p>
                  </div>

                  <img src={logo} alt="logo" />
                </PaymentDetails>

                <CreditCard
                  includeInputLabels
                  buttonProps={{
                    css: {
                      "[data-theme='dark'] &": {
                        backgroundColor: "#61dafb",
                        color: "var(--ifm-color-emphasis-100)",
                        "&:hover": {
                          backgroundColor: "#0091ea",
                        },
                      },
                      backgroundColor: "rgb(38,166,154)",
                      fontSize: "14px",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "rgb(38,166,154, 0.8)",
                      },
                    },
                  }}
                >
                  Pay ${selectedCard.price}
                </CreditCard>
              </>
              {/* <Ach accountHolderName="" /> */}
            </PaymentForm>
          </div>
        </PaymentModal>
      )}
    </>
  );
};

const PaymentDetails = styled.div`
  line-height: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 8em;
    margin-left: 10em;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 1em;
  margin: 1em;
  button {
    cursor: pointer;
    background-color: rgb(38, 166, 154);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.5em 1em;
    font-size: 1.2em;
  }
  button:hover {
    background-color: rgb(38, 166, 154, 0.8);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .title {
    font-size: 3em;
    font-weight: bold;
    color: ${(props) => props.theme.secondary};
    margin-top: 2em;
  }
  .content {
    text-align: center;
    width: 50em;
    line-height: 1.5em;
    font-size: 1.2em;
    max-width: 98%;
    p {
      font-family: "Montserrat", sans-serif;
    }
  }
`;

const PaymentModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner {
    width: max-content;
    height: max-content;
    background: white;
    padding: 20px;
    border-radius: 10px;
  }
`;

export default GiftCards;
