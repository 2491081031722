import HomeBanner from "Components/HomeBanner/HomeBanner";
import HomeGalary from "Components/HomeGalary/HomeGalary";
import HowItWorks from "Components/HowItWorks/HowItWorks";
import React from "react";
import { Container, ImgGrid } from "./Home.styles";
import Testimonials from "./Testimonials";
import pic1 from "assets/images/htw_pic_1.png";
import pic2 from "assets/images/htw_pic_2.png";
import pic3 from "assets/images/htw_pic_3.png";
import hwt from "assets/images/hwt.png";
import hwt2 from "assets/images/hwt2.png";
import bottomback from "assets/images/bottomback.png";
import {
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
} from "@mui/icons-material";
import ReactGA from "react-ga";
import { toast } from "react-toast";

ReactGA.pageview(window.location.pathname);

const Home = () => {
  const [email, setEmail] = React.useState("");

  const saveEmail = async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/subscribers/v1/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Email saved successfully!");
        } else {
          toast.error("Unable to save email!");
        }
      });
  };

  return (
    <Container back={bottomback}>
      <HomeBanner />
      <HowItWorks />
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <img
          src={hwt}
          style={{ width: "100%", marginBottom: "1em" }}
          alt="frames"
        />
        <ImgGrid>
          <img
            src={pic1}
            style={{ border: "5px solid #26A48E", padding: 20 }}
            alt="frames"
          />
          <img
            src={pic2}
            style={{ border: "5px solid #EA851F", padding: 20 }}
            alt="frames"
          />
          <img
            src={pic3}
            style={{ border: "5px solid #26A48E", padding: 20 }}
            alt="frames"
          />
        </ImgGrid>
        <img src={hwt2} style={{ width: "100%" }} alt="frames" />
      </div>

      <Testimonials />
      <div className="emailCol">
        <p>
          Momento means "<b>Moment</b>
          "<br /> Thus, we want your special "
          <b style={{ color: "#FFD19D" }}>instant</b>" to have an everlasting
          remembrance in your life!
        </p>
        <span className="label">Get all the updates and exclusive offers</span>
        <div className="form">
          <input
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Email"
          />
          <button onClick={saveEmail}>
            <ArrowForwardIos
              style={{
                width: "0.7em",
                height: "0.7em",
                marginTop: "2px",
                marginLeft: "2px",
                color: "RGB(87, 82, 62,0.7)",
              }}
            />
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Home;
