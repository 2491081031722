import { device } from "assets/theme/device";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3em 0;

  background-color: ${(props) => props.theme.main};
  @media ${device.mobileM} {
    flex-direction: column;
    align-items: start;
  }
`;
export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  .logoCont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    background-color: ${(props) => props.theme.tertiary};
    padding: 10px;
    padding-inline: 20px;
    border-radius: 10px;

    img {
      max-width: 90%;
    }
  }
  .copyright {
    font-size: 12px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120%;
    gap: 5px;
    margin-top: 25px;
    margin-left: -5em;
    @media ${device.mobileM} {
      margin-left: 0px;
      width: 100%;
    }
    a {
      width: 35%;
      text-decoration: none;

      img {
        border-radius: 10px;
        width: 100%;
        transition: all 0.2s ease-in-out;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .footer-links_1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    justify-content: space-between;
    width: 100%;
    @media ${device.mobileM} {
      flex-direction: column;
      align-items: start;
      gap: 4px;
      margin-top: 8px;
      margin-bottom: 0;
      margin-left: 40px;
      width: 90%;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 35px;
    width: 100%;
    @media ${device.mobileM} {
      flex-direction: column;
      align-items: start;
      padding: 4px 0;
      gap: 4px;
      margin-left: 40px;
      width: 90%;
    }
  }
  .footer-social {
    color: #fff;
    span {
      margin-right: -3.5em;
    }
    @media ${device.mobileM} {
      margin-top: 10px;
      margin-right: 0;
    }
    margin-right: -7em;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    .follow {
      font-size: 24px;
      font-weight: bold;
      @media ${device.mobileM} {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-right: 0;
        gap: 0;
      }
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      gap: 15px;
      svg {
        width: 40px;
        height: 40px;
        transition: all 0.2s ease-in-out;
        fill: white;
        &:hover {
          fill: ${(props) => props.theme.textSecondary};
        }
      }
    }
  }
  @media ${device.mobileL} {
    width: 100% !important;
    margin-bottom: 1em;
    color: #fff;
    .footer-links_1 {
      padding-left: 0px;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.textTertiary};
  font-size: 18px;
  font-weight: 550;
  &:hover {
    color: ${(props) => props.theme.textSecondary};
    font-weight: 700;
  }
`;
