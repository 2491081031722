import { Link, NavLink as NavLinks } from "react-router-dom";
import styled from "styled-components";
import { device } from "assets/theme/device";
export const Container = styled.div`
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  background-color: #fff;
  align-items: flex-end;
  padding-inline: 2em;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  .hamburg {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .mobile-backdrop {
    display: none;
  }
  @media ${device.mobileL} {
    padding-inline: 1em;
    .hamburg {
      display: block;
      svg {
        font-size: 2.4em;
      }
    }
    .mobile-backdrop {
      display: flex;
      position: fixed;
      top: 0;
      left: ${(props) => (props.isOpen ? 0 : -1000)}px;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: ${(props) => (props.isOpen ? 0.3 : 0)};
      z-index: 0;
      transition: opacity 1s ease-in-out;
    }
    .mobile-menu {
      display: flex;
      flex-direction: column;
      padding-left: 2em;
      padding-top: 5em;
      gap: 1em;
      position: fixed;
      top: 0;
      right: ${(props) => (props.isOpen ? 0 : -300)}px;
      bottom: 0;
      width: 250px;
      background-color: #fff;
      z-index: 900;
      transition: all 700ms ease-in-out;
      .closeIcon {
        width: auto;
        height: auto;
        margin-left: -20px;
        margin-top: -60px;
        margin-bottom: 1em;
      }
    }
  }
`;

export const LogoCont = styled(Link)`
  color: ${(props) => props.theme.textTertiary};
  text-decoration: none;
  background-color: ${(props) => props.theme.tertiary};
  height: 4em;
  display: flex;
  align-items: center;
  padding-inline: 1em;
  border-radius: 5px;

  img {
    height: 2.5em;
  }
`;

export const CartDot = styled.span`
  left: 10px;
  top: 4px;
  font-size: 0.7em;
  width: 15px;
  padding: 2px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.secondary};
  position: absolute;
`;

export const Collapsable = styled.div`
  display: flex;
  gap: 2em;
  @media ${device.mobileL} {
    display: none;
  }
`;

export const NavLink = styled(NavLinks)`
  color: ${(props) => props.theme.main};
  text-decoration: none;
  padding-inline: 1em;
  padding-block: 0.5em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  @media ${device.mobileL} {
    align-items: end;
    justify-content: end;
    white-space: nowrap;
  }
  gap: 0.5em;
  position: relative;
  font-size: 0.8em;

  &.active {
    font-weight: 1000;
  }

  &:hover {
    scale: 1.1;
  }
`;

export const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
  color: ${(props) => props.theme.textTertiary};
  text-decoration: none;
  padding-inline: 1em;
  padding-block: 0.5em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.active {
    font-weight: 700;
  }

  &:hover {
    background-color: ${(props) => props.theme.tertiary};
    color: ${(props) => props.theme.textSecondary};
  }
`;
