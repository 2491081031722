export const filterValues = [
  {
    label: "None",
    value: "",
  },
  {
    label: "1977",
    value: "filter-1977",
  },
  {
    label: "Aden",
    value: "filter-aden",
  },
  {
    label: "Amaro",
    value: "filter-amaro",
  },
  {
    label: "Ashby",
    value: "filter-ashby",
  },
  {
    label: "Brannan",
    value: "filter-brannan",
  },
  {
    label: "Brooklyn",
    value: "filter-brooklyn",
  },
  {
    label: "Charmes",
    value: "filter-charmes",
  },
  {
    label: "Clarendon",
    value: "filter-clarendon",
  },
  {
    label: "Crema",
    value: "filter-crema",
  },
  {
    label: "Dogpatch",
    value: "filter-dogpatch",
  },
  {
    label: "Earlybird",
    value: "filter-earlybird",
  },
  {
    label: "Gingham",
    value: "filter-gingham",
  },
  {
    label: "Ginza",
    value: "filter-ginza",
  },
  {
    label: "Hefe",
    value: "filter-hefe",
  },
  {
    label: "Helena",
    value: "filter-helena",
  },
  {
    label: "Hudson",
    value: "filter-hudson",
  },
  {
    label: "Inkwell",
    value: "filter-inkwell",
  },
  {
    label: "Kelvin",
    value: "filter-kelvin",
  },
  {
    label: "Kuno",
    value: "filter-juno",
  },
  {
    label: "Lark",
    value: "filter-lark",
  },
  {
    label: "Lo-Fi",
    value: "filter-lofi",
  },
  {
    label: "Ludwig",
    value: "filter-ludwig",
  },
  {
    label: "Maven",
    value: "filter-maven",
  },
  {
    label: "Mayfair",
    value: "filter-mayfair",
  },
  {
    label: "Moon",
    value: "filter-moon",
  },
  {
    label: "Nashville",
    value: "filter-nashville",
  },
  {
    label: "Perpetua",
    value: "filter-perpetua",
  },
  {
    label: "Poprocket",
    value: "filter-poprocket",
  },
  {
    label: "Reyes",
    value: "filter-reyes",
  },
  {
    label: "Rise",
    value: "filter-rise",
  },
  {
    label: "Sierra",
    value: "filter-sierra",
  },
  {
    label: "Skyline",
    value: "filter-skyline",
  },
  {
    label: "Slumber",
    value: "filter-slumber",
  },
  {
    label: "Stinson",
    value: "filter-stinson",
  },
  {
    label: "Sutro",
    value: "filter-sutro",
  },
  {
    label: "Toaster",
    value: "filter-toaster",
  },
  {
    label: "Valencia",
    value: "filter-valencia",
  },
  {
    label: "Vesper",
    value: "filter-vesper",
  },
  {
    label: "Walden",
    value: "filter-walden",
  },
  {
    label: "Willow",
    value: "filter-willow",
  },
  {
    label: "X-Pro II",
    value: "filter-xpro-ii",
  },
];
