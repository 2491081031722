import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "styled-components";

const AuthModal = (props) => {
  return (
    <OuterCont onClick={props.onClose}>
      <InnerCont
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <CloseIconSt onClick={props.onClose} />
        {props.children}
      </InnerCont>
    </OuterCont>
  );
};
const animatedDown = keyframes`
    0% {
        transform: scale(0.3);
        opacity: 0.2;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const OuterCont = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: brightness(0.8);
  display: flex;
  justify-content: center;
  z-index: 999;
`;
const InnerCont = styled.div`
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 5em;
  width: 40em;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  height: fit-content;
  position: relative;
  animation: ${animatedDown} 0.2s linear forwards;
`;

const CloseIconSt = styled(CloseIcon)`
  font-size: 2em;
  /* color: #fff; */
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 20px;
`;
export default AuthModal;
