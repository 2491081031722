import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@mui/material";
import { changePassword, getUser, updateDetails } from "apis/profile/auth";
import Loader from "Components/Loader/Loader";
import React from "react";
import styled from "styled-components";
import ListAddresses from "./ListAddresses";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import LogoutIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { Router, useNavigate } from "react-router-dom";
import { device } from "assets/theme/device";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import editIcon from "assets/images/editIcon.svg";
import ReactGA from "react-ga";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

ReactGA.pageview(window.location.pathname);

const Profile = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px solid #fff",
    outline: "2px solid #fff",
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [user, setUser] = React.useState({
    avatar: null,
    dob: null,
    fullname: "",
    gender: "N",
    id: 0,
    is_blocked: false,
    is_reported: false,
    is_verified: false,
    role: "M",
    theme: "L",
  });
  let [isLoading, setIsLoading] = React.useState(true);
  let [expanded, setExpanded] = React.useState(false);
  let [passForm, setPassForm] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  let [changingPass, setChangingPass] = React.useState(false);

  let navigate = useNavigate();

  const handleChangePassForm = (e) => {
    setPassForm({
      ...passForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePass = async (e) => {
    if (changingPass) return;
    setChangingPass(true);
    const response = await changePassword({
      old_password: passForm.oldPassword,
      new_password: passForm.newPassword,
    });
    if (response && response.success) {
      setPassForm({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setExpanded(false);
    }
    setChangingPass(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchUser = async () => {
    const response = await getUser();
    if (response && response.success) setUser(response.data);
    setIsLoading(false);
  };
  const getInitials = (name) => {
    let initials = name?.split(" ").map((name) => name[0]);
    return initials.join("");
  };

  async function EditUser(event) {
    event.preventDefault();
    const formData = {
      fullname: document.getElementById("editName").value,
      email: document.getElementById("editEmail").value,
      phone: document.getElementById("editPhone").value,
    };
    const response = await updateDetails(formData);
    if (response && response.data.success) {
      setUser(response.data);
    }
    handleClose();
    console.log(open);
    fetchUser();
  }

  React.useEffect(() => {
    fetchUser();
  }, []);

  return isLoading ? (
    <Loader message="Getting user details!" />
  ) : (
    <>
      <Container>
        {changingPass && <Loader absolute message="Changing your password!" />}
        <span className="header">PROFILE</span>
        <div className="userCont">
          <div className="user">
            <span>{getInitials(user?.fullname)}</span>
          </div>
          <div className="fieldsCont">
            <div className="username">
              <span>{user?.fullname}</span>
            </div>
            <div className="fields">
              <span className="userHeading">Email: </span>
              <span className="userField">{user?.email}</span>
            </div>
            <div>
              <span className="userHeading">Mobile Number: </span>
              <span className="userField">{user?.phone}</span>
            </div>
          </div>
          <div className="editButton" onClick={handleOpen}>
            <img src={editIcon} alt="edit" />
          </div>
        </div>
        <div className="section">
          <div className="accordianCont">
            <Accordion
              expanded={expanded === "never"}
              onChange={() => {
                navigate("/orders");
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon fontSize="medium" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <LocalMallOutlinedIcon style={{ color: "#07806B" }} />
                <Typography
                  variant="h8"
                  sx={{
                    flexShrink: 0,
                    marginLeft: "0.7em",
                    marginTop: "0.2em",
                  }}
                >
                  YOUR ORDERS
                </Typography>
              </AccordionSummary>
            </Accordion>
            <Accordion
              expanded={expanded === "never"}
              onChange={() => {
                navigate("/cart");
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon fontSize="medium" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ShoppingCartOutlinedIcon style={{ color: "#07806B" }} />
                <Typography
                  variant="h8"
                  sx={{
                    width: "33%",
                    flexShrink: 0,
                    marginLeft: "0.7em",
                    marginTop: "0.2em",
                  }}
                >
                  YOUR CART
                </Typography>
              </AccordionSummary>
            </Accordion>
            <Accordion
              expanded={expanded === "never"}
              onChange={() => {
                navigate("/my-gift-cards");
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon fontSize="medium" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ShoppingCartOutlinedIcon style={{ color: "#07806B" }} />
                <Typography
                  variant="h8"
                  sx={{
                    width: "33%",
                    flexShrink: 0,
                    marginLeft: "0.7em",
                    marginTop: "0.2em",
                  }}
                >
                  YOUR GIFT CARDS
                </Typography>
              </AccordionSummary>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon fontSize="medium" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <HttpsOutlinedIcon style={{ color: "#07806B" }} />
                <Typography
                  variant="h8"
                  sx={{
                    width: "50%",
                    flexShrink: 0,
                    marginLeft: "0.7em",
                    marginTop: "0.2em",
                  }}
                >
                  CHANGE PASSWORD
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="change-pass">
                  <TextField
                    label="Current Password"
                    type="password"
                    name="oldPassword"
                    value={passForm.oldPassword}
                    onChange={handleChangePassForm}
                  />
                  <TextField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    error={
                      passForm.newPassword !== passForm.confirmPassword ||
                      passForm.newPassword.length < 8
                    }
                    helperText={
                      passForm.newPassword !== passForm.confirmPassword
                        ? "Passwords do not match"
                        : passForm.newPassword.length < 8
                        ? "Password must be at least 8 characters long"
                        : ""
                    }
                    value={passForm.newPassword}
                    onChange={handleChangePassForm}
                  />
                  <TextField
                    label="Confirm New Password"
                    type="password"
                    name="confirmPassword"
                    error={
                      passForm.newPassword !== passForm.confirmPassword ||
                      passForm.confirmPassword.length < 8
                    }
                    helperText={
                      passForm.newPassword !== passForm.confirmPassword
                        ? "Passwords do not match"
                        : passForm.confirmPassword.length < 8
                        ? "Password must be at least 8 characters long"
                        : ""
                    }
                    value={passForm.confirmPassword}
                    onChange={handleChangePassForm}
                  />
                  <button onClick={handleChangePass}>Change Password</button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="addressCont">
            <Typography
              sx={{
                flexShrink: 0,
                fontWeight: 400,
                fontSize: "20px",
                marginLeft: "1em",
                marginTop: "20px",
              }}
              variant="h7"
            >
              Saved Address(es)
            </Typography>

            <ListAddresses fromProfile />
          </div>
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#07806B"}
            style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
          >
            EDIT PROFILE
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Enter the new email or phone.
          </Typography> */}
          <br />
          <TextField
            style={{ width: "100%" }}
            id="editName"
            label="Name"
            placeholder="John Doe"
            defaultValue={user?.fullname}
          />
          <p></p>
          <TextField
            style={{ width: "100%" }}
            id="editEmail"
            label="Email"
            placeholder="example@gmail.com"
            defaultValue={user?.email}
          />
          <p></p>
          <TextField
            style={{ width: "100%" }}
            id="editPhone"
            label="Phone Number"
            placeholder="+1 999 9999999"
            defaultValue={user?.phone}
          />
          <p></p>
          <div style={{ paddingBottom: "20px" }}>
            <Button onClick={EditUser}>
              <p>UPDATE</p>
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70vh;
  .change-pass {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }
  button {
    margin-inline: auto;
    display: flex;
    align-items: center;
    gap: 1em;
    border: none;
    width: 10em;
    font-size: 1em;
    justify-content: center;
    background: ${(props) => props.theme.secondary};
    color: #fff;
    padding-block: 0.7em;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 3em;
    @media ${device.mobileM} {
      width: 100%;
    }
  }
  .header {
    margin-top: 4em;
    font-size: 1.5em;
    font-weight: 600;
    color: #26a48e;
    margin-left: 2em;
    margin-bottom: 0.5em;
  }
  .userCont {
    display: flex;
    background-color: rgb(38, 166, 154, 0.7);
    padding: 2.5em;
  }

  .editButton {
    cursor: pointer;

    margin-top: 0.5em;
    margin-bottom: 0;
    display: flex;
    height: 20px;

    p {
      font-size: 22px;
      margin-top: -1px;
      margin-left: 5px;
    }
  }

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background: ${(props) => props.theme.secondary};
    color: #fff;
    span {
      font-size: 1.5em;
    }
    @media ${device.mobileM} {
      width: 6em;
      height: 6em;
      font-size: 1.3em;
      margin-top: 2em;
    }
  }
  .fieldsCont {
    margin-left: 2em;
  }
  .username {
    font-size: 1.5em;
    font-weight: 600;
    color: #fff;

    @media ${device.mobileM} {
      margin-bottom: 1em;
    }
  }
  .fields {
    line-height: 0.9em;
    margin-top: 0.5em;
  }
  .userHeading {
    color: rgb(0, 0, 0, 0.7);
    font-weight: 600;
    font-size: 0.8em;
  }
  .userField {
    font-weight: 600;
    font-size: 0.8em;
    margin-left: 0.5em;
    color: rgb(0, 0, 0, 0.7);
  }
  .section {
    display: flex;
    justify-content: center;
  }
  .accordianCont {
    width: 40em;
    max-width: 98%;
    padding: 2em;
    margin-bottom: 2em;
    gap: 1em;
    display: flex;
    flex-direction: column;
    padding-inline: 1em;
    @media ${device.mobileM} {
      margin-bottom: 1em;
      padding-bottom: 1em;
    }
  }
  .addressCont {
    border: 1px solid RGB(161, 215, 206);
    border-radius: 4px;
    padding: 1em;
    margin: 1.8em;
    width: 40em;
  }
  .MuiAccordion-root {
    border: 1px solid RGB(161, 215, 206);
    border-radius: 4px;
    box-shadow: none;
  }
`;

const Button = styled.div`
  position: absolute;
  right: 30px;
  padding-inline: 1em;
  font-family: "Montserrat";
  background-color: #26a48e;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  min-height: 30px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  input {
    display: none;
  }

  p {
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    margin: 0 12px;
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 10%),
      inset 0px 0px 10px rgba(0, 0, 0, 10%);
  }
`;

export default Profile;
