import { device } from "assets/theme/device";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background-color: #eeeeee;
  overflow-x: hidden;
  .title {
    display: flex;
    align-items: center;
    gap: 4em;
    margin-bottom: 2em;
    // margin-top: 1em;
    h1 {
      text-transform: capitalize;
      color: ${(props) => props.theme.secondary};
    }
    img {
      margin-top: 100px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    div {
      width: 400px;
      height: 300px;
      object-fit: contain;
      overflow: hidden;
    }

    @media ${device.tablet} {
      img {
        margin-top: 120px;
        width: 100%;
        height: 120%;
      }
    }

    @media ${device.mobileL} {
      img {
        width: 100%;
      }
    }

    @media ${device.mobileM} {
      flex-direction: column-reverse;
      padding-inline: 2em;
      gap: 1em;
      h1 {
        margin: 0;
      }
      img {
        margin-top: 120px;
        width: 120%;
      }
    }

    @media ${device.mobileS} {
      img {
        width: 130%;
        height: 60%;
      }
    }
  }
  .nav {
    background: linear-gradient(
      90deg,
      rgba(228, 135, 32, 0.48) 0%,
      rgba(38, 164, 142, 0.48) 105.83%
    );
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    margin-bottom: 2em;
    z-index: 0;

    @media ${device.tablet} {
      width: 600px;
    }
    @media ${device.mobileL} {
      width: 500px;
    }

    @media ${device.mobileM} {
      width: 300px;
    }
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.textPrimary};
      font-size: 1.1rem;
      padding: 10px;
      flex: 3;
      text-align: center;
      transition: all 0.3s ease-in-out;
      border-bottom-width: 0;
      position: relative;
      span {
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        filter: brightness(0.98);
      }
      &.active {
        color: white;
        font-weight: 700;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        border-radius: 30px;
        span {
          width: 100%;
        }
      }
    }
  }
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    margin-bottom: 2em;
    z-index: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.textPrimary};
      font-size: 1.1rem;
      padding: 10px;
      flex: 3;
      text-align: center;
      transition: all 0.3s ease-in-out;
      border-bottom-width: 0;
      position: relative;
      background-color: ${({ theme }) => theme.tertiary};

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 2px;
        background-color: ${({ theme }) => theme.secondary};
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        filter: brightness(0.98);
      }
      &.active {
        color: ${({ theme }) => theme.textSecondary};
        font-weight: 700;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        span {
          width: 100%;
        }
      }
    }
    @media ${device.mobileM} {
      width: 100%;
      margin-top: 0;
    }
  }
`;
