import React, { useState, useEffect } from "react";
import {
  CloseIconStyled,
  ModalInnerCont,
  ModalOuterCont,
} from "./Modal.styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import { useDebounceEffect } from './useDebounceEffect'
// import { imgPreview } from './imgPreview';
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import styled from "styled-components";

// function centerAspectCrop(
//   mediaWidth,
//   mediaHeight,
//   aspect,
// ) {
//   return centerCrop(
//     makeAspectCrop(
//       {
//         unit: '%',
//         width: 90,
//       },
//       aspect,
//       mediaWidth,
//       mediaHeight,
//     ),
//     mediaWidth,
//     mediaHeight,
//   )
// }
const StyledSelect = styled(Select)`
  border: #fff;
  background: #fff;
  min-width: 10em;
`;
const CropModal = (props) => {
  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState("");
  const [cropped, setCropped] = useState("");

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%",
      width: 30,
      aspect: undefined,
    }
  );

  const [imageRef, setImageRef] = useState();

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.jpeg" // destination filename
      );

      // calling the props function to expose
      // croppedImage to the parent component
      onImageCropped(croppedImage);
    }
  }
  const onImageCropped = (croppedImage) => {
    setCropped(croppedImage);
  };
  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }

        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const croppedImageUrl = window.URL.createObjectURL(blob);

        resolve(croppedImageUrl);
      }, "image/jpeg");
    });
  }
  const handleChange = (e) => {
    console.log(e);
    setCropConfig({
      ...cropConfig,
      aspect: e.target.value === "free" ? undefined : e.target.value,
    });
  };
  useEffect(() => {
    setImgSrc(localStorage.getItem("image"));
  }, []);

  return (
    <ModalOuterCont>
      <CloseIconStyled
        fontSize="32"
        color="#fff"
        onClick={(e) => {
          localStorage.removeItem("image");
          localStorage.removeItem("finalImgUrl");
          props.setCropModal(false);
        }}
      />
      <p className="title" style={{ display: "flex", alignItems: "center" }}>
        Crop your image to fit the frame of your choice.
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="crop-simple-select-autowidth-label">
            Aspect Ratio
          </InputLabel>
          <StyledSelect
            labelId="crop-simple-select-autowidth-label"
            id="crop-simple-select-autowidth"
            value={cropConfig.aspect}
            onChange={handleChange}
            label="Aspect Ratio"
            color="secondary"
          >
            <MenuItem value={"free"}>
              <em>Free</em>
            </MenuItem>
            <MenuItem value={1 / 1}>6x6</MenuItem>
            <MenuItem value={1 / 1}>8x8</MenuItem>
            <MenuItem value={7 / 5}>5x7</MenuItem>
            <MenuItem value={14 / 11}>11x14</MenuItem>
          </StyledSelect>
        </FormControl>
      </p>

      <ModalInnerCont>
        {Boolean(imgSrc) && (
          <ReactCrop
            src={imgSrc}
            crop={cropConfig}
            ruleOfThirds
            a
            onImageLoaded={(imageRef) => setImageRef(imageRef)}
            onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(cropConfig) => setCropConfig(cropConfig)}
            crossorigin="anonymous" // to avoid CORS-related problems
          />
        )}
      </ModalInnerCont>
      <span
        className="continue"
        onClick={async (e) => {
          localStorage.setItem("finalImgUrl", cropped);

          if (props?.fromCheckout) {
            props.setImageUrl(cropped);
            localStorage.removeItem("image");
            props.setCropModal(false);
            return;
          }

          navigate("/frames/small");
          localStorage.removeItem("image");
          props.setCropModal(false);
        }}
      >
        Continue
      </span>
    </ModalOuterCont>
  );
};

export default CropModal;
