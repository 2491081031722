import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "assets/theme/device";


const Container = styled.div`
    margin-left: 100px;
    width: 100px;
    @media ${device.tablet} {
        margin-left: 0;
        }
`;

const Slides = styled.div`
  display: ${(props) => (props.show === props.position ? "flex" : "none")};
  /* width: 400px; */
  /* width: fit-content; */
  /* border: blue solid 2px; */
  padding: 0 auto;
  place-content: center;

  & img {
    /* width: 100%; */

    @media (max-width: 524px) {
      height: auto; //enlargement of pics depends on the height rather than width thus solving overflow issues
      max-width: 100%;
    }
    margin-left: 100px;
    margin-top: 20px;
    height: 55vh; //this helps in scaling for large screen sizes
  }
`;


const Thumbnail = styled.div`
  justify-content: left;
  

`;

const Thumbs = styled.img`
  @media (max-width: 524px) {
    width: calc((100%) / 1.1);
    height: auto;
  }
  box-shadow: 0 0 5px 0 #00000025;
  border: 4px solid white ;
  margin: 0.3em;
  height: 10vh;
  width: auto;
  cursor: pointer;
  opacity: ${(props) => (props.where === props.current ? "0.5" : "1")};

  :hover {
    background-color: #717171;
    opacity: 0.5;
  }
`;

function Carousel({
  children,
  size,
  functionToCall,
  minWidth_for_function = 524,
}) {
  const [position, setPosition] = useState(1);

  // variable to check window width
  const width = window.innerWidth;

  const pixRef = useRef(null);
  useEffect(() => {
    const onpixClick = (event) => {
      // console.log(`click inside carousel`);
      // console.log(event.target);
      if (pixRef.current && pixRef.current.contains(event.target)) {
        console.log(`click inside carousel`);
        // toggleShow(false);
        if (width > minWidth_for_function) {
          // console.log("width is big");
          functionToCall(true);
        }
      }
    };
    document.addEventListener("click", onpixClick, true);
    return () => {
      document.removeEventListener("click", onpixClick, true);
    };
  }, []);

  return (
    <>
      <Container>
        <Thumbnail>
          {children.map((image, index) => (
            <div
              key={index}
              where={position}
              current={index}
              onClick={() => setPosition(index)}
            >
              <Thumbs
                src={image}
                where={position}
                current={index}
                alt="product"
              />
            </div>
          ))}
        </Thumbnail>

        {/* MAKE THIS FUNCTIONAL, CURRENTLY THE DISPLAY   */}
        {/* <div ref={pixRef}>
          {children.map((image, index) => (
            <Slides show={index} position={position} key={index}>
              <img src={image} alt="product" />
            </Slides>
          ))}
        </div> */}
      </Container>
    </>
  );
}
export default Carousel;
