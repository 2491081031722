import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container } from "./Frames.styles";
import S1 from "assets/images/header/small/sm-1.png";
import S2 from "assets/images/header/small/sm-2.png";
import smallheader from "assets/images/header/small/header.png";
import M1 from "assets/images/header/medium/M1.png";
import M2 from "assets/images/header/medium/M2.png";
import mediumheader from "assets/images/header/medium/header.png";
import L1 from "assets/images/header/large/L1.png";
import L2 from "assets/images/header/large/L2.png";
import largeheader from "assets/images/header/large/header.png";

import ReactGA from "react-ga";
import { CarouselProvider, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

ReactGA.pageview(window.location.pathname);

let header = {
  small: smallheader,
  medium: mediumheader,
  large: largeheader,
};

let images = {
  small: [S1, S2],
  medium: [M1, M2],
  large: [L1, L2],
};

const Frames = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let [activeTab, setActiveTab] = React.useState("");

  useEffect(() => {
    if (location.pathname.split("/").length > 2)
      setActiveTab(location.pathname.split("/")[2]);
    else navigate("/frames/small");
  }, [location.key]);

  const renderImages = () => {
    switch (activeTab) {
      case "large":
        return images.large.map((img, i) => (
          <img src={img} alt="frame" key={i} />
        ));
      case "medium":
        return images.medium.map((img, i) => (
          <img src={img} alt="frame" key={i} />
        ));
      default:
        return images.small.map((img, i) => (
          <img src={img} alt="frame" key={i} />
        ));
    }
  };

  return (
    <Container>
      <div
        style={{
          margin: 100,
          marginLeft: 0,
          marginRight: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={header[activeTab]} alt="frame" />
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={100}
          totalSlides={2}
          infinite={true}
          interval={2000}
          isPlaying={true}
        >
          <Slider
            style={{
              marginTop: 5,
            }}
          >
            {renderImages()}
          </Slider>
        </CarouselProvider>
      </div>

      {/* <div className="tabs">
        <NavLink to={"/frames/small"}>
          <span></span>Small
        </NavLink>
        <NavLink to={"/frames/medium"}>
          <span></span>Medium
        </NavLink>
        <NavLink to={"/frames/large"}>
          <span></span>Large
        </NavLink>
      </div> */}
      <div className="nav">
        <NavLink to={"/frames/small"}>
          <span></span>Small
        </NavLink>
        <NavLink to={"/frames/medium"}>
          <span></span>Medium
        </NavLink>
        <NavLink to={"/frames/large"}>
          <span></span>Large
        </NavLink>
      </div>
      <Outlet />
    </Container>
  );
};

export default Frames;
