import CropModal from "Components/Modal/CropModal";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { Container, UploadButton } from "./HomeBanner.styles";
import homeback1 from "../../assets/images/homeback1.png";
import { Paragraph } from "Components/HowItWorks/HowItWorks.styles";

const HomeBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cropModal, setCropModal] = useState(false);

  return (
    <>
      <Container back={homeback1}>
        <p>
          <br />
        </p>

        {/* <UploadButton>
          <label htmlFor="imageUpload">START FRAMING</label>
          <input
            type="file"
            onChange={(e) => {
              if (location.pathname.startsWith("/frames")) navigate("/");

              localStorage.removeItem("finalImgUrl");
              localStorage.removeItem("image");

              if (e?.target?.files && e.target.files.length > 0) {
                console.log(e?.target?.files[0]?.size > 30000);
                if (e?.target?.files[0]?.size > 30000) {
                  const reader = new FileReader();

                  reader.addEventListener("load", () => {
                    const image = reader.result;

                    localStorage.setItem("image", image);
                    setCropModal(true);
                  });

                  reader.readAsDataURL(e.target.files[0]);
                  setTimeout(() => {
                    e.target.files = null;
                  }, 5000);
                } else {
                  toast.error("File size must be greater than 300KB");
                }
              } else {
                localStorage.removeItem("image");
              }
              e.target.value = null;
            }}
            accept="image/*"
            id="imageUpload"
          />
        </UploadButton> */}
        <span>
          Momento is a company focused on offering solutions and improves your
          pictures. <br />
          Giving the opportunity to select from our frames and get the best
          match for your picture.
        </span>
      </Container>
      {cropModal && <CropModal setCropModal={setCropModal} />}
    </>
  );
};

export default HomeBanner;
