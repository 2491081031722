import { CircularProgress, TextField } from "@mui/material";
import { forgotPassword, resetPassword } from "apis/profile/auth";
import React from "react";
import styled from "styled-components";
import AuthModal from "../AuthModal";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);
const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [form2, setForm2] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = async (e) => {
    if (loading) return;
    setLoading(true);
    const response = await forgotPassword({ email });
    if (response && response?.success) {
      setForm2(true);
    }
    setLoading(false);
  };
  const handleSubmit2 = async (e) => {
    if (loading) return;
    setLoading(true);
    const response = await resetPassword({ email, password, code });
    if (response && response?.success) {
      props.setForgotPasswordModal(false);
    }
    setLoading(false);
  };

  return (
    <AuthModal
      onClose={() => {
        props.setForgotPasswordModal(false);
      }}
    >
      <HeaderRow>
        <h3>Forgot Password</h3>
      </HeaderRow>
      {!form2 && (
        <Form1>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "90%" }}
          />
          {loading ? (
            <CircularProgress style={{ marginTop: "2em" }} />
          ) : (
            <button onClick={handleSubmit}>Send Reset Code</button>
          )}
        </Form1>
      )}
      {form2 && (
        <Form1>
          <TextField
            type="text"
            label="Reset Code"
            style={{ width: "90%" }}
            value={code}
            onChange={(e) => {
              if (e.target.value?.length <= 6) setCode(e.target.value);
            }}
          />
          <TextField
            type="password"
            label="New Password"
            style={{ width: "90%", marginTop: "1em" }}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {loading ? (
            <CircularProgress style={{ marginTop: "2em" }} />
          ) : (
            <button onClick={handleSubmit2}>Reset Password</button>
          )}
        </Form1>
      )}
    </AuthModal>
  );
};

const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Form1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding-inline: 2em;
  button {
    width: 15em;
    background-color: ${(props) => props.theme.main};
    border: none;
    margin-top: 2em;
    border-radius: 5px;
    padding: 0.5em;
    height: 3em;
    font-size: 1em;
    color: #fff;
    cursor: pointer;
  }
`;

export default ForgotPassword;
