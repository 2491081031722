import { baseAddApi } from "apis/constants";
import { toast } from "react-toast";
import axiosApiInstance from "../axiosSetup";

const backendUrl = baseAddApi;
const backendUrl2 = process.env.REACT_APP_API_URL;

export const getShippingFee = async (zip) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/shipping?zip=${zip}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to get shipping fee!");
  }
};
export const getAddresses = async () => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl2 + "/user/v1/get-all-address"
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to get addresses!");
  }
};

export const deleteAddress = async (id) => {
  try {
    const response = await axiosApiInstance.delete(
      backendUrl2 + "/user/v1/delete-address/" + id
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to delete address!");
  }
};

export const getAutoComplete = async (query) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl2 + "/user/v1/get-address-query",
      { query: `${query} United States` }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to get auto complete!");
  }
};

export const addNewAddress = async (address) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl2 + "/user/v1/add-address",
      address
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to add address!");
  }
};

export const addToCart = async (frameId, image, rotation, filter) => {
  try {
    const data = new FormData();
    data.append("fid", frameId);
    data.append("rotation", rotation);
    data.append("filter", filter);
    let file = await fetch(image)
      .then((r) => r.blob())
      .catch((e) => console.log(e));
    data.append("poster", file);
    console.log(data);
    const response = await axiosApiInstance.post(
      backendUrl2 + "/order/v1/add-to-card",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    localStorage.removeItem("frame");
    localStorage.removeItem("image");
    localStorage.removeItem("finalImgUrl");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to add to cart!");
  }
};

export const getCart = async () => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl2 + "/order/v1/get-cart"
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to get cart!");
  }
};

export const removeFromCart = async (body) => {
  try {
    const response = await axiosApiInstance.delete(
      backendUrl2 + "/order/v1/del-cart",
      { data: body }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to remove from cart!");
  }
};

export const createGiftCardOrder = async (body) => {
  try {
    const response = await axiosApiInstance.put(
      backendUrl2 + "/order/v1/checkout/giftcard",
      body
    );

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(
      error.response?.data?.msg || "Failed to create gift card order!"
    );
  }
};

export const createOrder = async (body) => {
  try {
    const response = await axiosApiInstance.put(
      backendUrl2 + "/order/v1/checkout",
      body
    );
    localStorage.removeItem("frame");
    localStorage.removeItem("image");
    localStorage.removeItem("finalImgUrl");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to create order!");
  }
};

export const fetchAllOrders = async () => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl2 + "/order/v1/get-user-orders"
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to fetch all orders!");
  }
};

export const getOrder = async (oid) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl2 + "/order/v1/get-order/" + oid
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.msg || "Failed to get order!");
  }
};

export const getCartCount = async () => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl2 + "/order/v1/get-cart-count"
    );
    return response.data;
  } catch (error) {
    console.log(error);
    //toast.error(error.response?.data?.msg || "Failed to get cart count!");
  }
};

export const getGiftCards = async () => {
  try {
    const response = await axiosApiInstance.get(backendUrl2 + `/giftcard/v1/`);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to get user giftcard!");
    console.log(error);
  }
};
