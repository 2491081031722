import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { css } from "styled-components";

const Loader = (props) => {
  return (
    <Container absolute={props.absolute}>
      <CircularProgress />
      <p>{props.message}</p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 80vh;
  color: ${(props) => props.theme.secondary};
  z-index: 9999;
  opacity: 1;
  ${(props) =>
    props.absolute &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white;
    `}
`;

export default Loader;
