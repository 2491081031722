import { createSlice } from "@reduxjs/toolkit";

export const imageSlice = createSlice({
  name: "image",
  initialState: {
    small: {
      filter: { label: "Apply Filter", value: null },
      rotation: 0,
      index: { width: 0, height: 1 },
    },
    medium: {
      filter: { label: "Apply Filter", value: null },
      rotation: 0,
      index: { width: 0, height: 1 },
    },
    large: {
      filter: { label: "Apply Filter", value: null },
      rotation: 0,
      index: { width: 0, height: 1 },
    },
  },
  reducers: {
    imageReducer: (state, action) => {
      const { size, key, value } = action.payload;
      if (key === "reset") {
        console.log("resey");
        state[size] = {
          filter: { label: "Apply Filter", value: null },
          rotation: 0,
          index: { width: 0, height: 1 },
        };
      } else {
        state[size][key] = value;
      }
    },
  },
});

export const { imageReducer } = imageSlice.actions;
export default imageSlice.reducer;
