import Large from "Pages/Frames/Large/Large";
import Medium from "Pages/Frames/Medium/Medium";
import Small from "Pages/Frames/Small/Small";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { theme } from "./assets/theme/theme";
import Layout from "./Components/Layout/Layout";
import Frames from "./Pages/Frames/Frames";
import Home from "./Pages/Home/Home";
import { ToastContainer } from "react-toast";
import { createTheme, ThemeProvider as MUITheme } from "@mui/material/styles";
import { orange, teal, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import About from "Pages/Auxillary/About";
import Support from "Pages/Auxillary/Support";
import Profile from "Pages/Profile/Profile";
import Order from "Pages/Order/Order";
import Checkout from "Pages/Frames/Checkout/Checkout";
import Address from "Pages/Profile/Address";
import Cart from "Pages/Order/Cart";
import AuthProvider from "Components/AuthProvider/AuthProvider";
import TermsOfService from "Pages/Auxillary/TermsOfService";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ReactGA from "react-ga";
import GiftCard from "Pages/GiftCards";
import MyGiftCards from "Pages/GiftCards/MyGiftCards";

ReactGA.initialize("UA-192614868-2");
const muiTheme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: teal[500],
    },
    tertiary: {
      main: "#fff",
    },
    error: {
      main: red[500],
    },
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [isCartChanged, setIsCartChanged] = useState(0);

  const handleLogin = (val) => {
    setIsLoggedIn(val);
  };

  useEffect(() => {
    localStorage.removeItem("finalImgUrl");
    // if (window.innerWidth <= 600) {
    //   if (window.navigator.userAgent?.indexOf("Mobile") > 0) {
    //     window.open(
    //       "https://play.google.com/store/apps/details?id=com.momento.momento",
    //       "_blank"
    //     );
    //   }
    //   if (
    //     window.navigator.userAgent?.indexOf("iPhone") >= 0 ||
    //     window.navigator.userAgent?.indexOf("IOS") >= 0
    //   ) {
    //     window.open(
    //       "https://apps.apple.com/in/app/momento-frames/id1603778004",
    //       "_blank"
    //     );
    //   }
    // }
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <MUITheme theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Layout
                      isCartChanged={isCartChanged}
                      setIsLoggedIn={handleLogin}
                      isLoggedIn={isLoggedIn}
                    />
                  }
                >
                  <Route index element={<Home />} />
                  <Route
                    path="frames"
                    element={
                      <Frames
                        setIsLoggedIn={handleLogin}
                        isLoggedIn={isLoggedIn}
                      />
                    }
                  >
                    <Route
                      path="small"
                      element={
                        <Small
                          setIsLoggedIn={handleLogin}
                          isLoggedIn={isLoggedIn}
                        />
                      }
                    />
                    <Route
                      path="medium"
                      element={
                        <Medium
                          setIsLoggedIn={handleLogin}
                          isLoggedIn={isLoggedIn}
                        />
                      }
                    />
                    <Route
                      path="large"
                      element={
                        <Large
                          setIsLoggedIn={handleLogin}
                          isLoggedIn={isLoggedIn}
                        />
                      }
                    />
                  </Route>
                  <Route path="about-us" element={<About />} />
                  <Route path="contact" element={<Support />} />

                  <Route path="gift-cards" element={<GiftCard />} />

                  <Route
                    path="my-gift-cards"
                    element={
                      <AuthProvider>
                        <MyGiftCards />
                      </AuthProvider>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <AuthProvider>
                        <Profile />
                      </AuthProvider>
                    }
                  />
                  <Route
                    path="checkout"
                    element={<Checkout setIsCartChanged={setIsCartChanged} />}
                  />
                  <Route
                    path="orders"
                    element={
                      <AuthProvider>
                        <Order />
                      </AuthProvider>
                    }
                  />
                  <Route
                    path="cart"
                    element={
                      <AuthProvider>
                        <Cart />
                      </AuthProvider>
                    }
                  />

                  <Route path="privacy" element={<TermsOfService />} />
                </Route>
              </Routes>
            </ThemeProvider>
          </MUITheme>
        </BrowserRouter>
      </Provider>
      <ToastContainer position="bottom-right" delay={5000} />
    </>
  );
}

export default App;
