import React from 'react'
import styled from 'styled-components'
import nothing from "assets/images/icons/nothing.svg"

const Nothing = () => {
  return (
    <Container>
        <img src={nothing} alt="nothing" />
        <p>Nothing to show!</p>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    img{
        width: 40%;
    }
`;

export default Nothing