import styled from "styled-components";

export const ImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 8em;
  place-items: center;
  gap: 2em;
  margin-inline: 5em;
  img {
    width: 95%;
    height: 90%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
  .emailCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: url(${(props) => props.back});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    p {
      width: 400px;
      max-width: 100%;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      margin-block: 2.5em;
    }
    .label {
      width: 400px;
      max-width: 100%;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      margin-bottom: 1em;
    }
    .form {
      display: flex;
      margin-bottom: 3em;
      gap: 1em;
      input {
        width: 250px;
        height: 3.2em;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.61) 0%,
          rgba(237, 187, 136, 0.67) 100%
        );
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        outline: none;
        border: none;
        padding-left: 1em;
        color: rgba(0, 0, 0, 0.53);
      }
      input:hover {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.71) 0%,
          rgba(237, 187, 136, 0.77) 100%
        );
      }
      button {
        background: rgba(237, 187, 136, 0.76);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        outline: none;
        border: none;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.53);
      }
      button:hover {
        background: rgba(237, 187, 136, 0.86);
      }
    }
  }
`;
