import React from "react";
import {
  Column,
  Container,
  Copyright,
  StyledLink as Link,
} from "./Footer.styles";
import logo from "assets/images/logo.png";
import playstore from "assets/images/playstore.png";
import appstore from "assets/images/appstore.png";
import { ReactComponent as Facebook } from "assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/icons/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/icons/twitter.svg";
import { ReactComponent as Linkedin } from "assets/images/icons/linkedin.svg";
const Footer = () => {
  return (
    <>
      <Container>
        <Column style={{ width: "20%" }}>
          {/*  */}
          <div className="download">
            <a
              href="https://play.google.com/store/apps/details?id=com.momento.momento"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_200,h_65/icon-GooglePlay_1_zixjxl"
                alt="google-play"
              />
            </a>
            <a
              href="https://apps.apple.com/in/app/momento-frames/id1603778004"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_200,h_65/icon-AppStore_lg30tv"
                alt="app-store"
              />
            </a>
          </div>
        </Column>
        <Column style={{ marginLeft: "10%" }}>
          <div className="footer-links_1">
            <Link to="/about-us">About Us</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/terms">Terms of Use</Link>
            <Link to="/reviews">Reviews</Link>
          </div>

          <div className="footer-links">
            <Link to="/about-us">Blogs</Link>
            <Link to="/contact">Careers</Link>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </Column>
        <Column>
          <div className="footer-social">
            <span className="follow">Follow us</span>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/momento.in/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/momento.in/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                href="https://twitter.com/momento_in"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://www.linkedin.com/company/momento-frames"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin />
              </a>
            </div>
            {/*  */}
          </div>
        </Column>
      </Container>
      <Copyright>
        <div className="logoCont">
          <img src={logo} alt="logo" />
        </div>
        <span className="copyright">© All rights reserved by Momento LLC.</span>
      </Copyright>
    </>
  );
};

export default Footer;
