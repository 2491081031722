import { device } from "assets/theme/device";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-size: 27px;
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 560px;
  line-height: 1.1em;
  font-weight: 700;
  margin: auto;
  text-align: center;
  @media ${device.mobileL} {
    max-width: 100%;
    font-size: 20px;
    padding-inline: 1em;
  }
`;

export const StepsCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1500px;
  img {
      width: 70%;
      height: 562px;
      margin: 25px;
      border: 5px solid #e68721;
      border-radius: 5%;
    }
  .head {
    font-size: 20px;
    font-weight: 700;
    color: ${(props) => props.theme.textPrimary};
  }
  @media ${device.mobileL} {
    max-width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 210px;
    max-width: 100%;
    img {
      width: 50%;
      
    }
    p {
      color: ${(props) => props.theme.textPrimary};
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 10px;
      max-width: 230px;
      line-height: 0.9em;
      margin: auto;
    }
  }
  @media ${device.mobileM} {
    flex-direction: column;
  }
`;
