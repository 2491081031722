import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "assets/theme/device";
import {
  CartDot,
  Collapsable,
  Container,
  LogoCont,
  NavLink,
} from "./Navbar.styles";
import logo from "assets/images/logo.png";
import { toast } from "react-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Menu,
  CloseOutlined,
  Close,
  ShoppingCartCheckout,
  ShoppingBagOutlined,
  CropOriginal,
  Person,
  Info,
  Login,
  Logout,
  CardGiftcard,
} from "@mui/icons-material";
import { getCartCount } from "apis/order/order";
import CropModal from "Components/Modal/CropModal";

const Navbar = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const framingLocation = useLocation();
  const [cartCount, setCartCount] = React.useState(0);
  const [cropModal, setCropModal] = React.useState(false);

  useEffect(() => {
    setIsOpen(false);
    if (location.search?.includes("login=true")) {
      props.setLoginModal(true);
    }
  }, [location.key]);

  const getCartStatus = async () => {
    let response = await getCartCount();
    if (response && response.success) {
      console.log(response.data);
      setCartCount(response.data);
    }
  };

  useEffect(() => {
    if (props.isLoggedIn) {
      getCartStatus();
    }
  }, [props.isCartChanged]);

  return (
    <>
      <Container isOpen={isOpen}>
        <LogoCont to={"/"}>
          <img src={logo} alt="logo" />
        </LogoCont>
        <span className="hamburg" onClick={(e) => setIsOpen(true)}>
          <Menu color="secondary" />
        </span>
        <Collapsable>
          <Button>
            <label htmlFor="imageUploadNav">START FRAMING</label>
            <input
              type="file"
              onChange={(e) => {
                if (framingLocation.pathname.startsWith("/frames"))
                  navigate("/");

                localStorage.removeItem("finalImgUrl");
                localStorage.removeItem("image");

                if (e?.target?.files && e.target.files.length > 0) {
                  console.log(e?.target?.files[0]?.size > 30000);
                  if (e?.target?.files[0]?.size > 30000) {
                    const reader = new FileReader();

                    reader.addEventListener("load", () => {
                      const image = reader.result;

                      localStorage.setItem("image", image);
                      setCropModal(true);
                    });

                    reader.readAsDataURL(e.target.files[0]);
                    setTimeout(() => {
                      e.target.files = null;
                    }, 5000);
                  } else {
                    toast.error("File size must be greater than 300KB");
                  }
                } else {
                  localStorage.removeItem("image");
                }
                e.target.value = null;
              }}
              accept="image/*"
              id="imageUploadNav"
            />
          </Button>
          <NavLink to="/frames/small" bold>
            BROWSE FRAMES
          </NavLink>

          {/* {props.isLoggedIn && (
            <NavLink to="/orders">
              <ShoppingBagOutlined />
              ORDERS
            </NavLink>
          )} */}
          {/* {props.isLoggedIn && (
            <NavLink to="/cart">
              {cartCount > 0 && (
                <CartDot>{cartCount > 9 ? "9+" : cartCount}</CartDot>
              )}
              <ShoppingCartCheckout />
              CART
            </NavLink>
          )} */}
          <NavLink to="/gift-cards">
            <CardGiftcard /> Gift Cards
          </NavLink>
          {props.isLoggedIn && (
            <>
              <NavLink to="/profile">
                {" "}
                <Person /> PROFILE
              </NavLink>
              <NavLink to="/cart">
                {" "}
                <ShoppingCartCheckout /> CART
              </NavLink>
            </>
          )}
          {!props.isLoggedIn && (
            <NavLink
              to={"/login"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setLoginModal(true);
              }}
              bold
            >
              LOGIN
            </NavLink>
          )}
          {props.isLoggedIn && (
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("finalImgUrl");
                navigate("/");

                props.setIsLoggedIn(false);
              }}
              to="/logout"
            >
              LOGOUT
            </NavLink>
          )}
        </Collapsable>
        {window.innerWidth < 768 && (
          <div
            onClick={(e) => setIsOpen(false)}
            className="mobile-backdrop"
          ></div>
        )}
        {window.innerWidth < 768 && (
          <div className="mobile-menu">
            <span onClick={(e) => setIsOpen(false)} className="closeIcon">
              <Close color="secondary" />
            </span>
            <Button>Start Framing</Button>
            <NavLink to="/frames/small" bold>
              <CropOriginal /> BROWSE FRAMES
            </NavLink>

            {props.isLoggedIn && (
              <NavLink to="/orders">
                <ShoppingBagOutlined /> ORDERS
              </NavLink>
            )}
            {props.isLoggedIn && (
              <NavLink to="/cart">
                {cartCount > 0 && (
                  <CartDot>{cartCount > 9 ? "9+" : cartCount}</CartDot>
                )}
                <ShoppingCartCheckout />
                CART
              </NavLink>
            )}
            {props.isLoggedIn && (
              <NavLink to="/profile">
                <Person />
                PROFILE
              </NavLink>
            )}
            {!props.isLoggedIn && (
              <NavLink
                to={"/login"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.setLoginModal(true);
                }}
                bold
              >
                <Login />
                LOGIN
              </NavLink>
            )}
            {props.isLoggedIn && (
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  localStorage.removeItem("token");
                  localStorage.removeItem("refreshToken");
                  props.setIsLoggedIn(false);
                }}
                to="/logout"
              >
                <Logout />
                LOGOUT
              </NavLink>
            )}
          </div>
        )}
      </Container>
      {cropModal && <CropModal setCropModal={setCropModal} />}
    </>
  );
};

const Button = styled.div`
  font-family: "Montserrat";
  font-size: 1rem;
  background-image: linear-gradient(to right, #e48720, #26a48e);
  background: #fe6a00;
  border-radius: 80px;
  color: #00000040;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
  box-shadow: 1.5px 1.5px 3px rgb(0, 0, 0, 0.3),
    -1.5px -1.5px 3px rgb(0, 0, 0, 0.3);
  input {
    display: none;
  }
  label {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 500;
    color: #fff;

    @media ${device.laptop} {
    }
    @media ${device.mobileL} {
      font-size: 6px;
      padding-inline: 10%;
    }
  }

  @media ${device.mobileL} {
    margin-bottom: 10px;
    padding-bottom: 2.8em;
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 10%),
      inset 0px 0px 10px rgba(0, 0, 0, 10%);
  }
  &:hover {
    box-shadow: inset 3px 3px 3px rgb(0, 0, 0, 0.13),
      inset -3px -3px 3px rgb(0, 0, 0, 0.13);
  }
`;

export default Navbar;
