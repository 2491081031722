import { toast } from "react-toast";
import axiosApiInstance from "../axiosSetup";

const backendUrl = process.env.REACT_APP_API_URL;

export const signup = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl + `/user/v1/register`,
      data
    );
    toast.success("Successfully signed up!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to signup!");
    console.log(error);
  }
};

export const login = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl + `/user/v1/login`,
      data
    );
    toast.success("Successfully logged in!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to login!");
    console.log(error);
  }
};

export const getUser = async () => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/user/v1/get-user`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to get user details!");
    console.log(error);
  }
};

export const changePassword = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl + `/user/v1/change-password`,
      data
    );
    toast.success("Successfully changed password!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to change password!");
    console.log(error);
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl + `/user/v1/forgot-password`,
      data
    );
    toast.success("Successfully sent reset password link!");
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.msg || "Failed to send reset password link!"
    );
    console.log(error);
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      backendUrl + `/user/v1/reset-password`,
      data
    );
    toast.success("Successfully reset password!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to reset password!");
    console.log(error);
  }
};

export const updateDetails = async (data) => {
  console.log(data);
  try {
    const response = await axiosApiInstance.put(
      backendUrl + `/user/v1/update-details`,
      data
    );
    toast.success("Successfully updated details!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to update Details!");
    console.log(error);
  }
};
