import picard from "assets/images/picard.jpg";

export const testimonials = [
  {
    name: "MD Farhaan",
    location: "New York, NY",
    img: "https://avatars.githubusercontent.com/u/60516398?v=4",
    testimonial:
      "Lorem ipsum dolor si t amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    name: "Anant Mishra",
    location: "New York, NY",
    img: "https://avatars.githubusercontent.com/u/85404819?v=4",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    name: "Antariksh Ray",
    location: "New York, NY",
    img: "https://avatars.githubusercontent.com/u/41893738?v=4",
    testimonial:
      "Lorem ipsum dolor sit   am et, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];
