import React from "react";
import { Container, Paragraph, Row, StepsCont } from "./HowItWorks.styles";
import photo from "assets/images/icons/photo.svg";
import tools from "assets/images/icons/tools.svg";
import delivery from "assets/images/icons/delivery.svg";
import back from "assets/images/hwitwrks.png";

const HowItWorks = () => {
  return (
    <Container>
      <StepsCont back={back}>
        <span className="head">How it works?</span>
        <div className="backrow">
          <Row>
            {/* <div className="step">
              <img src={photo} alt="" />
              <p>Choose your photo and frame</p>
            </div> */}
            {/* <div className="step">
              <img src={tools} alt="" />
              <p>We handcraft it with love</p>
            </div> */}
            {/* <div className="step">
              <img src={delivery} alt="" />
              <p>Your order is delivered</p>
            </div> */}
          </Row>
        </div>
      </StepsCont>
    </Container>
  );
};

export default HowItWorks;
