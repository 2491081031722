import { createSlice } from "@reduxjs/toolkit";

export const frameSlice = createSlice({
  name: "frame",
  initialState: {
    frame: {
      id: "",
      size: "",
      rotaion: 0,
      filter: {
        label: "Apply Filter",
        value: null,
      },
      index: {
        width: 0,
        height: 1,
      },
    },
    redirect: false,
  },
  reducers: {
    frameReducer: (state, action) => {
      state.frame = { ...action.payload };
    },
    redirectReducer: (state, action) => {
      state.redirect = action.payload;
    },
  },
});

export const { frameReducer, redirectReducer } = frameSlice.actions;
export default frameSlice.reducer;
