import { mediaEndPoint, sizeList } from "apis/constants";
import { addNewAddress, getAddresses } from "apis/order/order";
import { getCart, removeFromCart } from "apis/order/order";
import Loader from "Components/Loader/Loader";
import Nothing from "Components/Nothing/Nothing";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import {
  Addresses,
  Address,
  MainContainer,
  RightCardContainer,
  LeftCardContainer,
  CartItemView2,
  Container,
} from "./Order.styles";
import remove from "../../assets/images/remove.png";
import "../../Components/Filter/filter.css";

import { getUser } from "apis/profile/auth";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Cart = (props) => {
  let [user, setUser] = React.useState({
    avatar: null,
    dob: null,
    fullname: "",
    gender: "N",
    id: 0,
    is_blocked: false,
    is_reported: false,
    is_verified: false,
    role: "M",
    theme: "L",
  });

  const fetchUser = async () => {
    const response = await getUser();
    if (response && response.success) setUser(response.data);
    setLoading(false);
  };

  const [addresses, setAddresses] = React.useState([]);
  const [phone, setPhone] = React.useState("");
  const [cart, setCart] = React.useState([]);
  const totalPrice = 150;
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const fetchAddresses = async () => {
    const response = await getAddresses();
    if (response && response.success) setAddresses(response.data);
    console.log(addresses);
    setLoading(false);
  };

  const fetchCart = async () => {
    const response = await getCart();
    if (response && response.success) setCart(response.data);
    setLoading(false);
  };
  const handleDelete = async (oid, fid) => {
    if (loading) return;
    setLoading(true);
    const response = await removeFromCart({ oid: oid, fid: fid });
    if (response && response.success) {
      toast.success("Item removed from cart!");
      await fetchCart();
    }
  };
  const handleOrderClick = (e) => {
    let frame = {
      price: e["price"],
      id: e["fid"],
      dim: e["dim"],
      dsc: e["dsc"],
      key: e["frame_key"],
      poster_key: e["poster_key"],
      size: e["size"],
      oid: e["oid"],
    };
    localStorage.setItem("frame", JSON.stringify(frame));
    navigate("/checkout");
  };

  const CartItem = ({ item }) => {
    const { rotation, filter } = item;

    let index;

    if (rotation == "90" || rotation == "270") {
      index = {
        w: 1,
        h: 0,
      };
    } else {
      index = {
        w: 0,
        h: 1,
      };
    }
    let dime = item?.dim?.split("x");
    let ratio = dime[0] / dime[1];
    let width = 300;
    let height = ratio * width;
    let ibd = item?.ibd?.split("x");
    let obd = item?.obd?.split("x");
    if (ibd && obd) {
      ibd[0] = parseInt(ibd[0]);
      ibd[1] = parseInt(ibd[1]);
      obd[0] = parseInt(obd[0]);
      obd[1] = parseInt(obd[1]);
      ibd[0] = (ibd[0] / obd[0]) * width;

      ibd[1] = (ibd[1] / obd[1]) * height;
      obd[0] = width;
      obd[1] = height;
    }
    return (
      <CartItemView2
        bgImg={mediaEndPoint + "/frames/" + item?.frame_key}
        height={height}
        width={width}
        innerHeight={ibd && ibd[index.h]}
        innerWidth={ibd && ibd[index.w]}
      >
        <div className="image-content">
          <div className="item-img">
            <img
              className="frameImg"
              src={`${mediaEndPoint}/frames/${item.frame_key}`}
              alt="frame"
              style={{ transform: `rotate(${rotation}deg)` }}
            />{" "}
            <img
              className={`img ${filter ? filter : ""}`}
              src={`${mediaEndPoint}/posters/${item.poster_key}`}
              alt="finalImg"
            />
          </div>
          <div className="item-info">
            <div className="item-name">{item?.dsc.toUpperCase()}</div>
            <div>
              Size {sizeList[item?.size]} ({item?.dim})
            </div>
            <div className="item-price">$ {item?.price}</div>
            <div className="delivery">Delivery by 15 August XXXX</div>
          </div>
        </div>
        <div className="btnSection">
          <div className="removeButton">
            {/* <button
              onClick={(e) => {
                handleDelete(item?.oid, item?.fid);
              }}
            ></button> */}
            {/* <DeleteOutlineIcon />
             */}

            <img
              src={remove}
              alt="remove"
              onClick={(e) => {
                handleDelete(item?.oid, item?.fid);
              }}
            />
          </div>
          <div className="checkoutButton">
            <button
              onClick={(e) => {
                handleOrderClick(item);
              }}
            >
              Checkout
            </button>
          </div>
        </div>
      </CartItemView2>
    );
  };

  const AddressShow = (prop) => {
    setPhone(prop.address.phone);
    return (
      <Address
        fromProfile={props.fromProfile}
        onClick={() => {
          if (props.setSelectedAddress) props.setSelectedAddress(prop.address);
          if (props.closeModal) props.closeModal();
        }}
      >
        <div className="row">{prop.address.address}</div>
        <div className="cityState">
          <div>
            <span>City : </span>
            <span>{prop.address.city}</span>
            <div>
              <span>State : </span>
              <span>{prop.address.state}</span>
            </div>
          </div>
        </div>
        <div className="cityState">
          <div>
            <span>Zipcode : </span>
            <span>{prop.address.zipcode}</span>
          </div>
          {/* <div>
            <span>Phone : </span>
            <span>{prop.address.phone}</span>
          </div> */}
        </div>
      </Address>
    );
  };

  useEffect(() => {
    fetchCart();
    fetchAddresses();
    fetchUser();
  }, []);

  return loading ? (
    <Loader message="Fetching Cart! Hold tight!" />
  ) : (
    <Container>
      <MainContainer>
        <LeftCardContainer>
          <div className="userInfo">
            <p>LOGIN</p>
            <div>&nbsp;</div>
            <div className="info">
              <div className="name">
                <div>{user?.fullname}</div>
                <div>+1 {phone}</div>
              </div>
              <a href="/profile">
                <button>
                  <div>CHANGE</div>
                </button>
              </a>
            </div>
          </div>
          <div className="userInfo">
            <p>SHIPPING ADDRESS</p>
            <div>&nbsp;</div>
            <div className="info">
              <div className="address">
                {addresses.length !== 0 && (
                  <Addresses>
                    <AddressShow
                      key={addresses[addresses.length - 1].id}
                      address={addresses[addresses.length - 1]}
                    />
                  </Addresses>
                )}
              </div>
              <a href="/profile">
                <button>
                  <div>CHANGE</div>
                </button>
              </a>
            </div>
          </div>
          {/* <div className="userInfo">
            <p className="payment">PAYMENT METHOD</p>
          </div> */}
        </LeftCardContainer>
        <RightCardContainer>
          <p>YOUR ORDER</p>
          <hr></hr>
          {cart.map((item) => (
            <CartItem key={item.oid} item={item} />
          ))}
          {cart?.length === 0 && <Nothing />}
          <h5>
            Delivery
            <span className="del-price">$50</span>
          </h5>
          {/* <h5>
            Discount
            <span className="disc-price">$50</span>
          </h5> */}
          <hr></hr>
          {/* <h1>
            TOTAL
            <span className="totalprice">${totalPrice}</span>
          </h1> */}
          {/* <div className="paybtn">
            <button
              className="pay"
              onClick={async (e) => {
                // if (!imgUrl) {
                //   toast.error("Please upload an image first!");
                //   return;
                // }
                // if (imgUrl.startsWith("http") || oid) {
                //   setShowPaymentForm(true);
                //   return;
                // }
                // if (!selectedAddress) {
                //   toast.error("Please select an address first!");
                //   return;
                // }
                // let resp = await addToCart(frame?.id, imgUrl);
                // if (resp && resp?.success) {
                //   setOid(resp?.data?.oid);
                //   setShowPaymentForm(true);
                // }
                // setShowPaymentForm(true);
              }}
            >
              PAY NOW
            </button>
          </div> */}
        </RightCardContainer>
      </MainContainer>
    </Container>
  );
};

export default Cart;
