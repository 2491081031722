import { PaymentOutlined, ShoppingCart } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { mediaEndPoint, sizeList, sizeList2 } from "apis/constants";
import { addToCart, createOrder, getShippingFee } from "apis/order/order";
import { makePayment } from "apis/payment/payment";
import Loader from "Components/Loader/Loader";
import AuthModal from "Pages/Authentication/AuthModal";
import ListAddresses from "Pages/Profile/ListAddresses";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import styled from "styled-components";
import Carousel from "./Carousel";
import {
  PaymentForm,
  CreditCard,
  Divider,
} from "react-square-web-payments-sdk";
import "react-square-payment-form/lib/default.css";
import stockRect from "assets/images/icons/stockRect.png";
import stockSquare from "assets/images/icons/stockSquare.png";
import { getOneFrame } from "apis/frames/frames";
import { ImageButton } from "Components/HomeBanner/HomeBanner.styles";
import CropModal from "Components/Modal/CropModal";
import { device } from "assets/theme/device";
import { redirectReducer } from "../../../redux/features/frame";
import logo from "assets/images/logo.png";

import product1 from "assets/images/image-product-1.png";
import product2 from "assets/images/image-product-2.png";
import product3 from "assets/images/image-product-3.png";
import product4 from "assets/images/image-product-4.png";
import ReactGA from "react-ga";
import { Button, Input, InputBase, Paper, TextField } from "@mui/material";

ReactGA.pageview(window.location.pathname);
// import product5 from

const backendUrl = process.env.REACT_APP_API_URL;

const Checkout = (props) => {
  const dispatch = useDispatch();
  const selectedFrame = useSelector((state) => state.frame.frame);
  const { filter, rotation, index, id } = selectedFrame;

  const images = [product1, product2, product3, product4];

  const [frame, setFrame] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [imgUrl, setImageUrl] = React.useState(
    localStorage.getItem("finalImgUrl") || null
  );
  const [shipping, setShipping] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [addressModal, setAddressModal] = React.useState(false);
  const [couponData, setCouponData] = React.useState({});
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [showPaymentForm, setShowPaymentForm] = React.useState(false);
  const [oid, setOid] = React.useState(null);
  const [loadingPayments, setLoadingPayments] = React.useState(false);
  const location = useLocation();
  const [cropModal, setCropModal] = React.useState(false);
  const [coupon, setCoupon] = React.useState("");
  const [error, setError] = React.useState("");

  const navigate = useNavigate();
  const currentDate = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.toLocaleString("default", { month: "long" });
    const year = today.getFullYear();

    let suffix;
    if (date === 1 || date === 21 || date === 31) {
      suffix = "st";
    } else if (date === 2 || date === 22) {
      suffix = "nd";
    } else if (date === 3 || date === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    const formattedDate = `${date}${suffix} ${month} ${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (couponData.type === "flat") {
      const discount = parseFloat(couponData.value);
      setDiscountAmount(discount);
    } else {
      const discount =
        (parseFloat(couponData.value) / 100) * parseFloat(frame?.price);
      if (discount > couponData.upperLimit) {
        setDiscountAmount(couponData.upperLimit);
      } else {
        setDiscountAmount(discount);
      }
    }
  }, [couponData]);

  const fetchFrame = async (id) => {
    if (id) {
      let response = await getOneFrame(id);
      if (response && response.success) {
        setFrame(response.data);
        await getShippingFees();
      } else {
        setFrame({});
      }
    } else {
      navigate("/frames/small");
    }
  };

  const handleCouponChange = (event) => {
    if (error) {
      setError(false);
    }
    setDiscountAmount(0);
    setCoupon(event.target.value);
  };

  const handleClearCoupon = () => {
    setCoupon("");
    setDiscountAmount(0);
    setError(false);
  };

  const handleApplyCoupon = () => {
    if (coupon.length === 24) {
      fetch(`https://api.momento.ink/api/giftcard/v1/validate/${coupon}`)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          if (res.valid) {
            // Coupon is valid
            setCouponData({ ...res.data, type: "flat", upperLimit: 0 });
          } else {
            // Coupon is not valid
            setError(res.msg);
          }
        });
    } else {
      fetch(`https://api.momento.ink/api/coupon/v1/validate/${coupon}`)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          if (res.valid) {
            // Coupon is valid
            setCouponData(res.data);
          } else {
            // Coupon is not valid
            setError(res.msg);
          }
        });
    }
    // .catch((error) => {
    //   console.log(error);
    //   setError("Error checking coupon code");
    // });
  };

  const getShippingFees = async () => {
    let response = await getShippingFee(11001);
    if (response && response?.success) {
      setShipping(response?.data);
    }
    setLoading(false);
    setAddressModal(false);
  };

  const handleAddToCart = async () => {
    if (oid) {
      toast.error("You have already added this frame to cart!");
      return;
    }
    let response = await addToCart(frame?.id, imgUrl, rotation, filter.value);
    if (response && response?.success) {
      setOid(response?.data?.oid);
      if (props.setIsCartChanged)
        props.setIsCartChanged(Math.random() * 100000);
      toast.success("Added to cart!");
    }
  };
  const cardNonceResponseReceived = async (tid) => {
    //   errors,
    //   nonce,
    //   cardData,
    //   buyerVerificationToken
    // ) => {
    //   if (errors) {
    //     console.log(errors);
    //     for (let i = 0; i < errors.length; i++) {
    //       toast.error(errors[i].message);
    //     }
    //     setLoadingPayments(false);
    //     return;
    //   }

    //alert("nonce created: " + nonce + ", buyerVerificationToken: " + buyerVerificationToken)
    if (!imgUrl) {
      toast.error("Please upload an image!");
      return;
    }
    let body = {
      // nonce: nonce,
      aid: "" + selectedAddress?.id,
      price:
        "" +
        (
          parseFloat(frame?.price) -
          parseFloat(discountAmount) +
          parseFloat(shipping)
        ).toFixed(2),
      id: "" + oid,
      tid: "" + tid,
      filter: filter.value,
      rotation: rotation,
    };
    console.log(body);
    let resp = await createOrder(body);
    if (resp && resp?.success) {
      toast.success("Order created!");
      if (props.setIsCartChanged)
        props.setIsCartChanged(Math.random() * 100000);
      navigate("/orders");
    } else {
      setShowPaymentForm(false);
      toast.error("Failed to create order!");
    }
    setLoadingPayments(false);
  };

  const createVerificationDetails = () => {
    setLoadingPayments(true);
    return {
      amount: (parseFloat(frame?.price) + parseFloat(shipping)).toFixed(2),
      currencyCode: "USD",
      intent: "CHARGE",
      billingContact: {
        familyName: "Smith",
        givenName: "John",
        email: "jsmith@example.com",
        country: "US",
        city: "London",
        addressLines: ["1235 Emperor's Gate"],
        postalCode: "SW7 4JA",
        phone: "020 7946 0532",
      },
    };
  };

  const getFrameCont = (frame) => {
    if (frame) {
      let dime = frame?.dim?.split("x");
      let ratio = dime[0] / dime[1];
      let width = 350;
      let height = ratio * width;
      let ibd = frame?.ibd?.split("x");
      let obd = frame?.obd?.split("x");
      if (ibd && obd) {
        ibd[0] = parseInt(ibd[0]);
        ibd[1] = parseInt(ibd[1]);
        obd[0] = parseInt(obd[0]);
        obd[1] = parseInt(obd[1]);
        ibd[0] = (ibd[0] / obd[0]) * width;

        ibd[1] = (ibd[1] / obd[1]) * height;
        obd[0] = width;
        obd[1] = height;
      }
      return (
        <FrameCont
          bImg={`${mediaEndPoint}/frames/${frame.key}`}
          ratio={ratio}
          height={height}
          width={width}
          innerHeight={ibd && ibd[index.height] + 1}
          innerWidth={ibd && ibd[index.width] + 2}
        >
          <div className="frame">
            <img
              className="frameImg"
              src={`${mediaEndPoint}/frames/${frame.key}`}
              alt="frame"
              style={{ transform: `rotate(${rotation}deg)` }}
            />
            <img
              className={`img ${filter.value}`}
              src={
                imgUrl
                  ? imgUrl
                  : frame.fimg ||
                    (ratio > 0.9 && ratio < 1.1 ? stockSquare : stockRect)
              }
              alt="finalImg"
            />
          </div>
        </FrameCont>
      );
    }
  };

  useEffect(() => {
    fetchFrame(id);

    let fram = JSON.parse(localStorage.getItem("frame"));
    if (fram) {
      setOid(fram?.oid);
      setImageUrl(mediaEndPoint + "/posters/" + fram?.poster_key);
      fetchFrame(fram?.id);
      //setAddressModal(true);
    }
    return () => {
      localStorage.removeItem("frame");
    };
  }, []);

  const checkLoggedIn = () => {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  };

  return loading ? (
    <Loader message="Fetching your frame!" />
  ) : (
    <Outer>
      {checkLoggedIn() && addressModal ? (
        <AuthModal onClose={() => setAddressModal(false)}>
          <ListAddresses
            isModal
            closeModal={() => setAddressModal(false)}
            setSelectedAddress={(e) => {
              setSelectedAddress(e);
            }}
          />
        </AuthModal>
      ) : null}
      <Header>Order Summary</Header>
      <Container>
        <Carousel size={images.length}>{images}</Carousel>
        <LeftContainer>
          <Frame>{frame && getFrameCont(frame)}</Frame>
          {!imgUrl && (
            <div className="uploadEdit" style={{ marginTop: "1em" }}>
              <ImageButton>
                <label htmlFor="imageUpload">Add Image</label>
                <input
                  type="file"
                  onChange={(e) => {
                    if (location.pathname.startsWith("/frames")) navigate("/");

                    localStorage.removeItem("finalImgUrl");
                    localStorage.removeItem("image");

                    if (e?.target?.files && e.target.files.length > 0) {
                      if (
                        e?.target?.files[0]?.size >
                        sizeList2[frame?.size] * 1000
                      ) {
                        const reader = new FileReader();

                        reader.addEventListener("load", () => {
                          const image = reader.result;

                          localStorage.setItem("image", image);
                          setCropModal(true);
                        });

                        reader.readAsDataURL(e.target.files[0]);
                        setTimeout(() => {
                          e.target.files = null;
                        }, 5000);
                      } else {
                        toast.error(
                          `File size must be greater than ${
                            sizeList2[frame?.size]
                          }KB`
                        );
                      }
                    } else {
                      localStorage.removeItem("image");
                    }
                    e.target.value = null;
                  }}
                  accept="image/*"
                  id="imageUpload"
                />
              </ImageButton>
            </div>
          )}
        </LeftContainer>
        <RightContainer>
          <div className="order-summary">
            <p className="dsc">{frame?.dsc.toUpperCase()} </p>
            <p>
              {frame?.dim} inches ({sizeList[frame?.size]})
            </p>
            <PriceContainer>
              <FramePrice
                style={{
                  textDecoration: !!discountAmount ? "line-through" : "none",
                }}
              >
                ${parseFloat(frame?.price).toFixed(2)}
              </FramePrice>
              {!!discountAmount && (
                <FramePrice>
                  $
                  {(
                    parseFloat(frame?.price) - parseFloat(discountAmount)
                  ).toFixed(2)}
                </FramePrice>
              )}
            </PriceContainer>
            {/* need a apply button inside textfield */}
            <div className="coupon-container" style={{ marginBottom: "2em" }}>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  error={error}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Coupon or Gift code"
                  value={coupon}
                  onChange={handleCouponChange}
                  inputProps={{ "aria-label": "Coupon code" }}
                />
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                <Button
                  onClick={
                    !!discountAmount ? handleClearCoupon : handleApplyCoupon
                  }
                  variant="text"
                  sx={{ p: "10px 24px", color: "black" }}
                >
                  {!!discountAmount ? "Clear" : "Apply"}
                </Button>
              </Paper>
              {error && (
                <p
                  className="error"
                  style={{
                    color: "red",
                  }}
                >
                  {error}
                </p>
              )}
            </div>
            <SubmitBtnRow>
              <button
                className="cart"
                onClick={(e) => {
                  if (!imgUrl) {
                    toast.error("Please upload an image first!");
                    return;
                  }
                  if (imgUrl?.startsWith("http")) {
                    toast.error("Item already in cart!");
                    return;
                  }
                  if (!checkLoggedIn()) {
                    dispatch(redirectReducer("/checkout"));
                    navigate("/?login=true");
                  } else {
                    handleAddToCart();
                  }
                }}
              >
                Add to Cart
              </button>
              <button
                onClick={async (e) => {
                  if (!imgUrl) {
                    toast.error("Please upload an image first!");
                    return;
                  }
                  if (!checkLoggedIn()) {
                    dispatch(redirectReducer("/checkout"));
                    navigate("/?login=true");
                  }
                  if (!selectedAddress) {
                    setAddressModal(true);
                    toast.error("Please select an address first!");
                    return;
                  }
                  if (imgUrl.startsWith("http") || oid) {
                    setShowPaymentForm(true);
                    return;
                  }

                  let resp = await addToCart(frame?.id, imgUrl);
                  if (resp && resp?.success) {
                    setOid(resp?.data?.oid);
                    setShowPaymentForm(true);
                  }
                }}
              >
                Buy Now
              </button>
            </SubmitBtnRow>
          </div>
          <div className="props">Product Details</div>

          <ul>
            <li>7" x 5" print</li>
            <li>9" x 7" frame interior</li>
            <li>10” x 8” frame exterior</li>
            <li>Scratch resistant 2mm glass</li>
            <li>High quality MDF wood</li>
            <li>Printed and hand assembled in the United States</li>
            <li>
              Thick stock, acid-free mat made from conservation core paper
            </li>
          </ul>

          {cropModal && (
            <CropModal
              setImageUrl={setImageUrl}
              fromCheckout={true}
              setCropModal={setCropModal}
            />
          )}
        </RightContainer>
      </Container>

      {showPaymentForm && selectedAddress && (
        <PaymentModal onClick={(e) => setShowPaymentForm(false)}>
          <div
            className="inner"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PaymentForm
              // applicationId={process.env.REACT_APP_SANDBOX_APPLICATION_ID}
              // locationId={process.env.REACT_APP_SANDBOX_LOCATION_ID}
              applicationId="sandbox-sq0idb-i7_e-75TTAulfT4X6q3xgg"
              locationId="BRHN2SWCJYMBH"
              cardTokenizeResponseReceived={async (token, buyer) => {
                const paymentbody = {
                  fid: frame?.id,
                  oid: oid,
                  sourceId: token.token,
                };
                console.log(paymentbody);
                const response = await makePayment(paymentbody);
                await cardNonceResponseReceived(response.data.id);
                console.log(response);
                toast.success("Payment Successful");
                // alert(JSON.stringify(await response, null, 2));
              }}
            >
              <>
                <PaymentDetails>
                  <div>
                    <p>Payment Details</p>
                    <h2>${frame.price}</h2>
                    <h2>{frame.dsc} Frame</h2>
                    <p>{currentDate()}</p>
                  </div>

                  <img src={logo} alt="logo" />
                </PaymentDetails>
                <CreditCard
                  includeInputLabels
                  buttonProps={{
                    css: {
                      "[data-theme='dark'] &": {
                        backgroundColor: "#61dafb",
                        color: "var(--ifm-color-emphasis-100)",
                        "&:hover": {
                          backgroundColor: "#0091ea",
                        },
                      },
                      backgroundColor: "rgb(38,166,154)",
                      fontSize: "14px",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "rgb(38,166,154, 0.8)",
                      },
                    },
                  }}
                >
                  Pay
                </CreditCard>
              </>
              {/* <Ach accountHolderName="" /> */}
            </PaymentForm>
          </div>
        </PaymentModal>
      )}
    </Outer>
  );
};

const PaymentDetails = styled.div`
  line-height: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 8em;
    margin-left: 10em;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  margin-bottom: 1em;
`;

const FramePrice = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`;

const PaymentModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner {
    width: max-content;
    height: max-content;
    background: white;
    padding: 20px;
    border-radius: 10px;
  }
`;
const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SubmitBtnRow = styled.div`
  display: flex;
  justify-content: left;
  margin-top: -1em;
  margin-bottom: 2em;
  gap: 2em;
  button {
    height: 2em;
    color: #fff;
    width: 10em;
    padding-block: 0.75em;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    background-color: #26a48e;
    border-radius: 80px;
    outline: none;
    border: none;
    cursor: pointer;
    @media ${device.mobileL} {
      width: 20em;
    }
    &:active {
      transform: scale(0.99);
    }
  }
  .cart {
    background-color: ${(props) => props.theme.main};
  }
`;

const Header = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.secondary};
`;
const FrameCont = styled.div`
  // display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  height: fit;
  .frame {
    margin-bottom: 3em;
    margin-top: -6.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 10px rgba(245, 129, 0, 0.1);
    padding: 0px;
    width: ${(props) => (props.width ? props.width : 250)}px;
    height: ${(props) => props.height}px;
    position: relative;
    .frameImg {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .img {
      width: ${(props) => props.innerWidth}px;
      height: ${(props) => props.innerHeight}px;
      transform: scale(1);
      transform-origin: 0 0;
      margin: 0px;
      object-fit: cover;
    }
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  width: 90em;
  max-width: 98%;
  margin-bottom: 6em;
  @media ${device.tablet} {
    display: block;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  justify-content: center;
  margin-top: 100px;
  @media ${device.tablet} {
    margin-top: -300px;
    margin-left: 200px;
    margin-right: 50px;
  }
`;

const Frame = styled.div`
  margin-top: 2em;
  margin-bottom: 1em;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 100px;
  margin-right: 50px;
  @media ${device.mobileL} {
    width: 300px;
  }
  @media ${device.mobileS} {
    width: 250px;
  }

  .order-summary {
    .dsc {
      margin-top: 70px;
      color: #26a48e;
      font-size: 2em;
      font-family: Montserrat;
      font-weight: bold;
      // color: ${(props) => props.theme.secondary};
      margin-bottom: 0.7em;
      @media ${device.tablet} {
        margin-top: 150px;
      }
    }
  }
  .props {
    margin-left: 10px;
    font-size: 1em;
    font-family: Montserrat;
    font-weight: 700 !important;
  }
`;

export default Checkout;

{
  /* <fieldset className="sq-fieldset">
                <CreditCardNumberInput />
                <div className="sq-form-third">
                  <CreditCardExpirationDateInput />
                </div>

                <div className="sq-form-third">
                  <CreditCardPostalCodeInput />
                </div>

                <div className="sq-form-third">
                  <CreditCardCVVInput />
                </div>
              </fieldset>

              {loadingPayments ? (
                <CircularProgress color="primary" />
              ) : (
                <CreditCardSubmitButton>
                  Pay $
                  {(parseFloat(frame?.price) + parseFloat(shipping)).toFixed(2)}
                </CreditCardSubmitButton>
              )} */
}

{
  /* <span className="address">Address</span>
            <address>
              {selectedAddress?.address}
              <br />
              {selectedAddress?.city}, {selectedAddress?.state}
              <br />
              Zipcode : {selectedAddress?.zipcode}
              <br />
              Phone : {selectedAddress?.phone}
            </address> */
}
