import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { FAQs } from "assets/static/faqs";
import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const About = () => {
  return (
    <Container>
      <div className="title">About Momento</div>
      <div className="content">
        <p>
          Momento is the result of having dressed the artwork of our thousands
          of clients during the past 40 years. We want to bring our expertise
          and craftsmanship who is looking for something special at affordable
          prices
        </p>
        <p>
          Momento means "Moment", thus, we want your special "instant" to have
          an everlasting remembrance in your life.
        </p>
        <p>
          The creation of our designs are based on what our customers have found
          to be more identify with. Therefore, this site is also a reflection of
          you!
        </p>
      </div>
      <div className="faq">
        <div className="title">FAQs</div>
        {FAQs.map((faq, index) => (
          <StyledAccordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>
                <p>{faq.question}</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "text.secondary" }}>
                <p>{faq.answer}</p>
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  padding: 2em;
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50em;
    margin-top: 2em;
    button {
      font-size: 1em;
      padding-inline: 1.5em;
      padding-block: 0.7em;
      outline: none;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      background-color: ${(props) => props.theme.secondary};
      color: #fff;
    }
  }
  .title {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 2em;
  }
  .content {
    width: 50em;
    max-width: 98%;
    p {
      font-family: "Montserrat", sans-serif;
    }
    margin-bottom: 2em;
  }
  .faq {
    width: 50em;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 98%;
  }
`;

const StyledAccordion = styled(Accordion)`
  font-family: "Montserrat", sans-serif;
  border-radius: 10px !important;
  border: none !important;
  width: 100%;
  margin-bottom: 1em;
`;

export default About;
