import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getGiftCards } from "apis/order/order";

const MyGiftCards = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGiftCards();
      console.log(result);
      setData(result.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Container>
      <h1 className="title">My Gift Cards</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {data.length === 0 && <p>You have no gift cards</p>}
          <div className="grid">
            {data.map((item, idx) => (
              <Card>
                <h2>Gift Card {idx + 1}</h2>
                <p>Value: ${item.value}</p>
                <p>Status: {item.status.toUpperCase()}</p>
                <p>Code: {item.code}</p>
              </Card>
            ))}
          </div>
        </>
      )}
    </Container>
  );
};

export default MyGiftCards;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 1em;
  margin: 1em;
  button {
    cursor: pointer;
    background-color: rgb(38, 166, 154);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.5em 1em;
    font-size: 1.2em;
  }
  button:hover {
    background-color: rgb(38, 166, 154, 0.8);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .title {
    font-size: 3em;
    font-weight: bold;
    color: ${(props) => props.theme.secondary};
    margin-top: 2em;
  }
  .content {
    text-align: center;
    width: 50em;
    line-height: 1.5em;
    font-size: 1.2em;
    max-width: 98%;
    p {
      font-family: "Montserrat", sans-serif;
    }
  }
`;
