import { toast } from "react-toast";
import axiosApiInstance from "../axiosSetup";

const backendUrl = process.env.REACT_APP_API_URL;

export const getSmallFrames = async (mat = false) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/utils/v1/get-all-frame?size=S&mat=${mat}&shape=R`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to load frames!");
    console.log(error);
  }
};

export const getMedFrames = async (mat = false) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/utils/v1/get-all-frame?size=M&mat=${mat}&shape=R`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to load frames!");
    console.log(error);
  }
};

export const getLargeFrames = async (mat = false) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/utils/v1/get-all-frame?size=L&mat=${mat}&shape=R`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to load frames!");
    console.log(error);
  }
};

export const getOneFrame = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      backendUrl + `/utils/v1/get-frame/${id}`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.msg || "Failed to load frames!");
    console.log(error);
  }
};
