import { TextField } from "@mui/material";
import { device } from "assets/theme/device";
import Loader from "Components/Loader/Loader";
import React from "react";
import { toast } from "react-toast";
import styled from "styled-components";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname);

const Support = () => {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleFormSubmit = () => {
    if (
      !Object.keys(formData).reduce((prev, key) => prev && formData[key] !== "")
    ) {
      toast.error("Fill in all the specified details!");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      setFormData({});
      toast.success("Submitted your query!");
      setIsLoading(false);
    }, 1000);
  };
  const handleFormDataChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <Container>
      {isLoading && <Loader absolute message="Sending query!" />}
      <div className="contact-form">
        <span className="title">Contact Us</span>
        <TextField
          onChange={handleFormDataChange}
          style={{ width: "90%", marginBottom: "1em" }}
          label="Name"
          type="text"
          value={formData.name}
          name="name"
        />
        <TextField
          onChange={handleFormDataChange}
          style={{ width: "90%", marginBottom: "1em" }}
          label="Email"
          type="text"
          name={"email"}
          value={formData.email}
        />
        <TextField
          onChange={handleFormDataChange}
          style={{ width: "90%", marginBottom: "1em" }}
          label="Subject"
          type="text"
          name="subject"
          value={formData.subject}
        />
        <TextField
          onChange={handleFormDataChange}
          style={{ width: "90%", marginBottom: "1em" }}
          multiline
          rows={3}
          label="Message"
          type="text"
          value={formData.message}
          maxRows={3}
          name="message"
        />
        <button onClick={handleFormSubmit}>Submit</button>
      </div>
      <div className="map">
        <iframe
          title="Momento LLC"
          src="https://maps.google.com/maps?q=301%20Queens%20Gate%20Ct%20Las%20Vegas&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          id="gmap_canvas"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  padding: 2em;
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50em;
    margin-top: 2em;
    max-width: 100%;
    @media ${device.mobileM} {
      width: 100%;
      margin-top: 0;
    }
    button {
      font-size: 1em;
      padding-inline: 1.5em;
      padding-block: 0.7em;
      outline: none;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      background-color: ${(props) => props.theme.secondary};
      color: #fff;
    }
  }
  .title {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 2em;
  }
  .map {
    width: 50em;
    max-width: 100%;
    margin-top: 3em;
    iframe {
      width: 50em;
      height: 30em;
      max-width: 100%;
      @media ${device.mobileM} {
        height: 20em;
      }
    }
  }
`;

export default Support;
